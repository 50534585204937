import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { AUTH_ROUTES, DASHBOARD_ROUTES } from "@/constants/routes";
import { useAuthStore } from "@/services";

export function UserMenu() {
  const user = useAuthStore((state) => state.user);

  return (
    <Menu>
      <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
        <Flex alignItems="center" gap={2}>
          <Flex
            color={useColorModeValue("black", "white")}
            display={{ base: "none", md: "flex" }}
            alignItems="center"
            gap={1}
          >
            <Text fontSize="md">{user?.first_name}</Text>
            <Text fontWeight="bold">({user?.role})</Text>
          </Flex>
          <IconButton
            as={Box}
            size="lg"
            aria-label="toggle menu"
            variant="ghost"
            display={{ base: "flex", md: "none" }}
          >
            <FiChevronDown />
          </IconButton>

          <Box display={{ base: "none", md: "flex" }}>
            <FiChevronDown />
          </Box>
        </Flex>
      </MenuButton>
      <MenuList
        bg={useColorModeValue("white", "gray.900")}
        color={useColorModeValue("black", "white")}
        borderColor={useColorModeValue("gray.200", "gray.900")}
      >
        <MenuItem
          as={Link}
          to={DASHBOARD_ROUTES.HOME}
          bg={useColorModeValue("white", "gray.900")}
          _hover={{
            bgColor: useColorModeValue("gray.100", "gray.700"),
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          as={Link}
          to={DASHBOARD_ROUTES.ACCOUNT}
          bg={useColorModeValue("white", "gray.900")}
          _hover={{
            bgColor: useColorModeValue("gray.100", "gray.700"),
          }}
        >
          Settings
        </MenuItem>
        <MenuDivider />
        <MenuItem
          as={Link}
          to={AUTH_ROUTES.LOGOUT}
          bg={useColorModeValue("white", "gray.900")}
          _hover={{
            bgColor: useColorModeValue("gray.100", "gray.700"),
          }}
        >
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
