import { Link } from "react-router-dom";
import { LinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Heading,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { CustomAvatar } from "@/components";
import { SocialButton } from "@/components/footer/components";
import { AUTH_ROUTES } from "@/constants/routes";
import { useAuthStore } from "@/services";
import { MentorType } from "@/types";
import { getFullName } from "@/utils/get-user-name";
import { getRelativeTimeString } from "@/utils/relative-date";
interface MentorCardProps {
  isAuthenticated?: boolean;
  mentor: MentorType;
  onApply: (selected: MentorType) => void;
}

export function MentorCard({
  isAuthenticated = false,
  mentor,
  onApply,
}: MentorCardProps) {
  const role = useAuthStore((state) => state.user?.role);
  const isMentee = role === "mentee";

  return (
    <Flex
      direction="column"
      maxW={{ base: "full", md: "275px" }}
      w="full"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      flexShrink={0}
      p={5}
      bg={useColorModeValue("white", "gray.900")}
      rounded="lg"
      alignItems="center"
    >
      <CustomAvatar name={getFullName(mentor)} />
      <Heading fontSize="2xl" fontFamily="body">
        {getFullName(mentor)}
      </Heading>
      {mentor.email && (
        <Text fontWeight={600} color="gray.500" mb={4}>
          {mentor.email}
        </Text>
      )}

      <Text
        textAlign="center"
        color={useColorModeValue("gray.700", "gray.400")}
        py={3}
      >
        {mentor.tagline || "Mentor"}
      </Text>
      <Text
        mt={4}
        fontSize="xs"
        fontWeight="bold"
        textAlign="center"
        color={useColorModeValue("gray.700", "gray.400")}
      >
        Joined O&apos;Mentors:
      </Text>
      <Text fontSize="xs" textAlign="center" colorScheme="blue" noOfLines={1}>
        {getRelativeTimeString(mentor.created_at)}
      </Text>

      <Flex pt={8} mt="auto" gap={4} w="full" maxW="md">
        {mentor.external_link && (
          <SocialButton
            href={mentor.external_link}
            label="Link"
            boxSize="40px"
            _focus={{
              bg: "gray.200",
            }}
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
          >
            <LinkIcon />
          </SocialButton>
        )}
        <Button
          {...(isAuthenticated
            ? isMentee
              ? {
                  onClick: () => onApply(mentor),
                }
              : {}
            : {
                as: Link,
                to: AUTH_ROUTES.LOGIN,
              })}
          flex={1}
          rounded="full"
          colorScheme="brand"
          bg={useColorModeValue("#151f21", "gray.700")}
          color="white"
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg",
          }}
          isDisabled={isAuthenticated && !isMentee}
        >
          {isAuthenticated && !isMentee ? "You aren't a mentee" : "Apply"}
        </Button>
      </Flex>
    </Flex>
  );
}

export function MentorCardSkeleton() {
  return (
    <Flex
      maxW={{ base: "full", md: "275px" }}
      w="full"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      flexShrink={0}
      p={5}
      rounded="lg"
      alignItems="center"
      direction="column"
    >
      <SkeletonCircle boxSize="96px" mb={4} pos="relative" />
      <SkeletonText w="60%" skeletonHeight="1.8rem" noOfLines={1} />
      <SkeletonText
        w="90%"
        noOfLines={2}
        py={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        spacing="0.5rem"
        skeletonHeight="1.25rem"
      />
      <SkeletonText
        w="25%"
        mt={4}
        noOfLines={1}
        skeletonHeight="1rem"
        color={useColorModeValue("gray.700", "gray.400")}
      />
      <SkeletonText
        w="45%"
        mt={1}
        noOfLines={1}
        skeletonHeight="1rem"
        color={useColorModeValue("gray.700", "gray.400")}
      />
      <Stack mt={8} direction="row" spacing={4} w="full" maxW="md">
        <Skeleton
          h="40px"
          flex={1}
          fontSize="sm"
          rounded="full"
          bg="#151f21"
          color="white"
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg",
          }}
        />
      </Stack>
    </Flex>
  );
}
