import { captureNetworkError } from "@/services/error-handler";
import { PublicMentorType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getPublicMentors request
 */

export type GetPublicMentorsResponse = PublicMentorType[];

export const getPublicMentors = async <T extends GetPublicMentorsResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("/public/mentors", options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};
