import { NavLink } from "react-router-dom";
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import {
  Activity,
  Diamonds,
  DocumentText,
  type Icon as IconType,
  Profile,
  Teacher,
  UserEdit,
  Warning2,
} from "iconsax-react";

import type { NavItemProps } from "./config";

const iconMap: Record<string, IconType> = {
  Teacher: Teacher,
  Profile: Profile,
  Activity: Activity,
  Warning2: Warning2,
  Setting2: UserEdit,
  DocumentText: DocumentText,
  Diamonds: Diamonds,
};

export function NavItem({ icon, href = "#", children, ...rest }: NavItemProps) {
  return (
    <Box
      as={NavLink}
      to={href}
      style={{ textDecoration: "none" }}
      color={useColorModeValue("black", "white")}
      _focus={{ boxShadow: "none" }}
    >
      {({ isActive }: { isActive: boolean }) => (
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "brand.600",
            color: "brand.200",
          }}
          gap={2}
          {...(isActive
            ? {
                bg: "brand.400",
              }
            : {})}
          {...rest}
        >
          {icon && (
            <Icon
              as={iconMap[`${icon}`]}
              fontSize={20}
              _groupHover={{
                color: "brand.200",
              }}
            />
          )}
          <Text fontWeight={400}>{children}</Text>
        </Flex>
      )}
    </Box>
  );
}
