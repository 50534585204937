import { LoaderFunctionArgs } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { SharedAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

export const getApplicationsQuery = () => ({
  queryKey: ["applications"],
  queryFn: SharedAPI.getApplications,
});

export const applicationsLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const query = getApplicationsQuery();

      const res =
        queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query));

      return res;
    } catch (error) {
      captureNetworkError(error);
    }
  };
