import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from "@chakra-ui/react";

import { SingleService } from "@/pages/dashboard/services/single-service";
import { MentorType, ServiceType } from "@/types";

import { EmptyState, LoadingState } from "../index";

interface MentorDetailsProps {
  mentor: MentorType;
}

function MentorDetails({ mentor }: MentorDetailsProps) {
  return (
    <Flex direction="column" gap={6}>
      <Heading fontSize="2xl" fontWeight="semibold" mb={2}>
        Mentor Services:
      </Heading>
      {!mentor ? (
        <LoadingState />
      ) : mentor.services.length ? (
        <Stack
          direction={{ base: "column", md: "row" }}
          textAlign="center"
          justify="flex-start"
          spacing={{ base: 4, lg: 10 }}
          overflowX="auto"
        >
          {mentor.services.map((service, index) => (
            <SingleService
              key={service.id}
              index={index + 1}
              service={service as ServiceType}
            />
          ))}
        </Stack>
      ) : (
        <EmptyState
          name="services"
          emptyText="They don't added any services yet."
        />
      )}
    </Flex>
  );
}

interface MentorModalProps extends Omit<ModalProps, "children"> {
  mentor: MentorType;
}

export function MentorModal({
  mentor,
  isOpen,
  onClose,
  ...props
}: MentorModalProps) {
  return mentor ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "full", sm: "xl" }}
      // isCentered
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          {mentor ? <MentorDetails mentor={mentor} /> : <LoadingState />}
        </ModalBody>
        <ModalFooter gap={4}></ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  );
}
