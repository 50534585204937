import { ElementRef, useRef, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Add } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { MenteeAPI } from "@/services";
import { GoalType } from "@/types";

import { DeleteGoalModal } from "./delete-goal-modal";
import { getGoalsQuery } from "./loading";
import { ModifyGoalModal } from "./modify-goal-modal";
import { SingleGoal } from "./single-goal";

export function GoalsPage() {
  const { data: initialData, isFetching } =
    useQuery<MenteeAPI.GetGoalsResponse>(getGoalsQuery());
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef<ElementRef<"input">>(null);

  const [mode, setMode] = useState<"create" | "edit" | "delete">();
  const [selected, setSelected] = useState<GoalType | undefined>();

  const handleEditGoal = (selectedGoal: GoalType) => {
    setSelected(selectedGoal);
    setMode("edit");
    onOpen();
  };

  const handleDeleteGoal = (selectedGoal: GoalType) => {
    setSelected(selectedGoal);
    setMode("delete");
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    setMode("create");
    onClose();
  };

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        p={{ base: 5, md: 20 }}
        align="flex-start"
        justify="center"
      >
        <Stack
          spacing={4}
          w="full"
          maxW="3xl"
          bg={useColorModeValue("white", "gray.900")}
          color={useColorModeValue("gray.700", "gray.200")}
          rounded="xl"
          boxShadow="lg"
          p={6}
        >
          <Flex
            pb={4}
            borderBottom={1}
            borderStyle="solid"
            borderColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent="space-between"
          >
            <Heading fontSize={{ base: "2xl", sm: "3xl" }}>My Goals</Heading>
            {Boolean(initialData?.length) && (
              <Button
                variant="ghost"
                colorScheme="gray"
                size="sm"
                onClick={onOpen}
              >
                <Add size={20} />
              </Button>
            )}
          </Flex>
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : initialData?.length ? (
              initialData.map((goal, index) => (
                <SingleGoal
                  key={goal.id}
                  index={index + 1}
                  goal={goal}
                  onEdit={handleEditGoal}
                  onDelete={handleDeleteGoal}
                />
              ))
            ) : (
              <EmptyState name="goal" onClick={onOpen} />
            )}
          </Flex>
        </Stack>
      </Flex>
      {mode === "delete" && selected ? (
        <DeleteGoalModal
          key={+isOpen}
          initialRef={initialRef}
          selectedGoal={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      ) : (
        <ModifyGoalModal
          key={+isOpen}
          initialRef={initialRef}
          selectedGoal={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}
