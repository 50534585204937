import { MutableRefObject } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MenteeAPI, successToast } from "@/services";
import type { BackgroundToolType, BackgroundType } from "@/types";

export function DeleteToolModal({
  initialRef,
  finalRef,
  background,
  selectedTool,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  background: BackgroundType;
  selectedTool: BackgroundToolType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending: isSubmitting } = useMutation({
    mutationKey: [`edit-background`],
    mutationFn: (data: Parameters<typeof MenteeAPI.editBackground>[0]) =>
      MenteeAPI.editBackground(data),
  });

  const handleDeleteTool = async () => {
    try {
      const res = await mutateAsync({
        ...background,
        tools: background.tools.filter(
          (item) => item.name !== selectedTool.name,
        ),
      });

      if (res.status === 200) {
        successToast({
          title: `Your background tool removed successfully!`,
        });
        onClose();

        await queryClient.invalidateQueries({
          queryKey: ["background"],
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Deleting This Tool:{" "}
          <Text
            as="span"
            p="2px"
            borderBottom="3px solid"
            borderBottomColor="brand.500"
          >
            {selectedTool.name}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <Text> Are you sure?</Text>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteTool}
            isLoading={isSubmitting}
            colorScheme="red"
            w="full"
          >
            Yes, Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
