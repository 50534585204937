import { captureNetworkError } from "@/services/error-handler";
import { MentorType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getMentors request
 */

export type GetMentorsResponse = MentorType[];

export const getMentors = async <T extends GetMentorsResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("mentors", options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * getSingleMentor request
 */

export type GetSingleMentorResponse = MentorType;

export const getSingleMentor = async <T extends GetSingleMentorResponse>(
  mentor_id: MentorType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`mentors/${mentor_id}`, options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};
