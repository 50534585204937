import { MutableRefObject } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { SharedAPI, successToast } from "@/services";
import { ApplicationType } from "@/types";

export function CancelApplicationModal({
  initialRef,
  finalRef,
  selectedApplication,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLTextAreaElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedApplication: ApplicationType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending: isSubmitting } = useMutation({
    mutationKey: [`cancel-application`, selectedApplication.id],
    mutationFn: (
      application_id: Parameters<typeof SharedAPI.cancelApplication>[0],
    ) => SharedAPI.cancelApplication(application_id),
  });

  const handleCancelApplication = async () => {
    try {
      const res = await mutateAsync(selectedApplication.id);

      if (res.status === 200) {
        successToast({
          title: `Your application is cancelled successfully!`,
        });
        onClose();

        await queryClient.invalidateQueries({
          queryKey: ["applications"],
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Withdraw This Application</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <Text> Are you sure?</Text>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose} disabled={isSubmitting}>
            No
          </Button>
          <Button
            onClick={handleCancelApplication}
            isLoading={isSubmitting}
            colorScheme="red"
            w="full"
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
