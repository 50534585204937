import { LoaderFunctionArgs } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { NotificationAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

export const getNotificationListQuery = () => ({
  queryKey: ["notifications"],
  queryFn: NotificationAPI.getNotificationList,
});

export const notificationsLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const query = getNotificationListQuery();

      return (
        queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query))
      );
    } catch (error) {
      captureNetworkError(error);
    }
  };
