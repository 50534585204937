import { ModalProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { MenteeModal, MentorModal } from "@/components";
import { getSingleMenteeQuery, getSingleMentorQuery } from "@/pages/dashboard";
import { PublicAPI } from "@/services";
import { ApplicationType, MenteeType, MentorType } from "@/types";

interface AuthorDetailsProps {
  isMentee: boolean;
  application?: ApplicationType;
}

export function AuthorDetails({
  isMentee,
  application,
  isOpen,
  onClose,
}: AuthorDetailsProps & Omit<ModalProps, "children">) {
  const { data: details } = useQuery<
    PublicAPI.GetSingleMenteeResponse | PublicAPI.GetSingleMentorResponse
  >(
    isMentee
      ? getSingleMentorQuery((application?.mentor as MentorType).id)
      : getSingleMenteeQuery((application?.mentee as MenteeType).id),
  );

  return isMentee ? (
    <MentorModal
      key={+isOpen}
      mentor={details as MentorType}
      isOpen={isOpen}
      onClose={onClose}
    />
  ) : (
    <MenteeModal
      key={+isOpen}
      mentee={details as MenteeType}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}
