import {
  Badge,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { notificationTypeMapping } from "@/constants/mappings";
import { NotificationType } from "@/types";
import { dateFormatter } from "@/utils/date-formatter";
import { getRelativeTimeString } from "@/utils/relative-date";

interface SingleNotificationProps {
  notification: NotificationType;
  onClick: (selected: NotificationType) => void;
}

export function SingleNotification({
  notification,
  onClick,
}: SingleNotificationProps) {
  const notificationMap = notificationTypeMapping(notification.notifier)[
    notification.type
  ];

  return (
    <Flex
      w="full"
      rounded="md"
      p={4}
      overflow="hidden"
      borderWidth="1px"
      borderRadius="md"
      gap={2}
      direction="column"
      flexShrink={0}
      onClick={() => onClick(notification)}
      bg={useColorModeValue(
        notification.status === "unread" ? "white" : "gray.50",
        notification.status === "unread" ? "gray.900" : "gray.800",
      )}
      _hover={{
        bgColor: useColorModeValue("gray.100", "gray.700"),
      }}
      cursor="pointer"
      opacity={useColorModeValue(
        notification.status === "unread" ? "100%" : "70%",
        notification.status === "unread" ? "100%" : "60%",
      )}
    >
      <Stack>
        <Heading
          color={useColorModeValue("gray.700", "white")}
          fontSize="xl"
          fontFamily="body"
          textAlign="start"
          _firstLetter={{ textTransform: "uppercase" }}
        >
          {notificationMap.label}
        </Heading>
      </Stack>
      <HStack justify="space-between" align="center">
        <Badge
          colorScheme={notification.status === "unread" ? "brand" : "gray"}
          textTransform="uppercase"
          letterSpacing={1.1}
        >
          {notification.status === "unread" ? "new" : "read"}
        </Badge>
        <Text color="gray.500" textAlign="start" fontSize="xs">
          {`${dateFormatter(notification.timestamp, {
            dateStyle: "medium",
            timeStyle: "short",
          })} (${getRelativeTimeString(notification.timestamp)})`}
        </Text>
      </HStack>
    </Flex>
  );
}
