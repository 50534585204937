import { QueryClient } from "@tanstack/react-query";

import { PublicAPI } from "@/services";

export const getPublicMentorsQuery = () => ({
  queryKey: ["public-mentors"],
  queryFn: PublicAPI.getPublicMentors,
});

export const homeLoader = (queryClient: QueryClient) => async () => {
  const query = getPublicMentorsQuery();

  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
