import { Link } from "react-router-dom";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { LoginCurve } from "iconsax-react";

import {
  AUTH_ROUTES,
  DASHBOARD_ROUTES,
  PUBLIC_ROUTES,
} from "@/constants/routes";
import { useAuthStore } from "@/services";

import { DesktopNav, MobileNav } from "./components";

export function Header() {
  const { isOpen, onToggle } = useDisclosure();
  const { isAuthenticated } = useAuthStore();

  const user = useAuthStore((state) => state.user);

  return (
    <Box
      as="header"
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.600", "white")}
    >
      <Flex
        minH="60px"
        py={{ base: 4 }}
        px={{ base: 6 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align="center"
      >
        <Flex w="100%" justify={{ base: "space-between" }} alignItems="center">
          <Flex display={{ base: "flex", lg: "none" }}>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant="ghost"
              aria-label="Toggle Navigation"
            />
          </Flex>
          <Flex as={Link} to={PUBLIC_ROUTES.HOME} alignItems="center" gap={2}>
            <Image
              src="/assets/ofallon-labs-logo.png"
              alt="O'FALLON LABS LLC"
              boxSize={10}
            />
            <Text
              textAlign={useBreakpointValue({ base: "center", lg: "left" })}
              fontFamily="heading"
              color={useColorModeValue("gray.800", "white")}
            >
              O&apos;Mentors
            </Text>
          </Flex>

          <Flex display={{ base: "none", lg: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>

          <Flex>
            {isAuthenticated ? (
              <Stack
                flex={{ base: 1, lg: 0 }}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <Button as={Link} to={DASHBOARD_ROUTES.HOME} variant="link">
                  <Text>{user?.first_name}</Text>
                </Button>
                <Button
                  as={Link}
                  to={AUTH_ROUTES.LOGOUT}
                  variant="ghost"
                  colorScheme="brand"
                  p={0}
                >
                  <LoginCurve size={20} />
                </Button>
              </Stack>
            ) : (
              <Stack
                flex={{ base: 1, lg: 0 }}
                justify="flex-end"
                direction="row"
                spacing={6}
              >
                <Button
                  as={Link}
                  to={AUTH_ROUTES.LOGIN}
                  fontSize="sm"
                  fontWeight={400}
                  variant="link"
                >
                  Login
                </Button>
                <Button
                  as={Link}
                  to={AUTH_ROUTES.REGISTER}
                  display={{ base: "none", lg: "inline-flex" }}
                  fontSize="sm"
                  fontWeight={600}
                  color="white"
                  bg="brand.400"
                  _hover={{
                    bg: "brand.300",
                  }}
                >
                  Register
                </Button>
              </Stack>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}
