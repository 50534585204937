import { createStandaloneToast, UseToastOptions } from "@chakra-ui/react";

const { ToastContainer, toast } = createStandaloneToast({
  defaultOptions: {
    duration: 5000,
    isClosable: true,
  },
});

export { toast, ToastContainer };

export const successToast = ({ title, ...rest }: UseToastOptions) =>
  toast({
    status: "success",
    title,
    ...rest,
  });

export const failedToast = ({ title, ...rest }: UseToastOptions) =>
  toast({
    status: "error",
    title,
    ...rest,
  });
