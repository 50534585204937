import { Flex, FlexProps, Spinner } from "@chakra-ui/react";

export function LoadingState(props: FlexProps) {
  return (
    <Flex
      direction="column"
      w="100%"
      h="100%"
      p={20}
      align="center"
      justify="center"
      {...props}
    >
      <Spinner
        thickness="5px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
      />
    </Flex>
  );
}
