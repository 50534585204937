import { ElementRef, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Flex,
  Heading,
  IconButton,
  Input,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { SearchNormal } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { SharedAPI, useAuthStore } from "@/services";
import { MentorshipActionStatusTypes, MentorshipType } from "@/types";
import { filterByName } from "@/utils/filter-by-name";

import { AuthorDetails } from "./author-details";
import { FinishMentorshipModal } from "./finish-mentorship-modal";
import { getMentorshipsQuery } from "./loading";
import { NextMentorshipModal } from "./next-mentorship-modal";
import { QuitMentorshipModal } from "./quit-mentorship-modal";
import { SingleMentorship } from "./single-mentorship";

export function MentorshipPage() {
  const { data: initialData, isFetching } =
    useQuery<SharedAPI.GetMentorshipsResponse>(getMentorshipsQuery());
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef<ElementRef<"input">>(null);

  const [mode, setMode] = useState<
    MentorshipActionStatusTypes | "details" | undefined
  >();
  const [selected, setSelected] = useState<MentorshipType | undefined>();

  const role = useAuthStore((state) => state.user?.role);
  const isMentee = role === "mentee";

  const { register, watch } = useForm<{ query: string }>({
    defaultValues: { query: "" },
  });

  const filteredMentorships = initialData?.filter(
    (mentorship) =>
      filterByName(
        isMentee
          ? mentorship.mentor?.first_name || ""
          : mentorship.mentee?.first_name || "",
        watch("query"),
      ) ||
      filterByName(
        isMentee
          ? mentorship.mentor?.last_name || ""
          : mentorship.mentee?.last_name || "",
        watch("query"),
      ),
  );

  const handleShowDetails = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode("details");
    onOpen();
  };

  const handleFinishMentorship = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode("done");
    onOpen();
  };

  const handleNextMentorship = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode("next");
    onOpen();
  };

  const handleQuitMentorship = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode("quitted");
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    setMode(undefined);
    onClose();
  };

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        p={{ base: 5, md: 20 }}
        align="flex-start"
        justify="center"
      >
        <Stack
          spacing={4}
          w="full"
          maxW="3xl"
          bg={useColorModeValue("white", "gray.900")}
          color={useColorModeValue("gray.700", "gray.200")}
          rounded="xl"
          boxShadow="lg"
          p={6}
        >
          <Flex
            pb={4}
            borderBottom={1}
            borderStyle="solid"
            borderColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent="space-between"
            gap={6}
            direction={{ base: "column", lg: "row" }}
          >
            <Heading fontSize={{ base: "2xl", sm: "3xl" }}>
              {isMentee ? "My Mentors" : "My Mentees"}
            </Heading>
            <Stack direction="row">
              <Input
                size="sm"
                placeholder="Search..."
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300",
                }}
                {...register("query")}
              />
              <IconButton
                aria-label="Search"
                size="sm"
                colorScheme="brand"
                icon={<SearchNormal />}
              />
            </Stack>
          </Flex>
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : filteredMentorships?.length && role ? (
              <Stack
                direction={{ base: "column", md: "row" }}
                textAlign="center"
                justify="flex-start"
                spacing={{ base: 4, lg: 10 }}
                py={10}
                overflowX="auto"
              >
                {filteredMentorships.map((mentorship) => (
                  <SingleMentorship
                    key={mentorship.id}
                    role={role}
                    mentorship={mentorship}
                    onDetail={handleShowDetails}
                    onQuit={handleQuitMentorship}
                    onNext={handleNextMentorship}
                    onFinish={handleFinishMentorship}
                  />
                ))}
              </Stack>
            ) : (
              <EmptyState name="active mentorship" />
            )}
          </Flex>
        </Stack>
      </Flex>
      {selected &&
        (mode === "details" ? (
          <AuthorDetails
            isMentee={isMentee}
            mentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === "done" ? (
          <FinishMentorshipModal
            key={+isOpen}
            initialRef={initialRef}
            selectedMentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === "next" ? (
          <NextMentorshipModal
            key={+isOpen}
            initialRef={initialRef}
            selectedMentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === "quitted" ? (
          <QuitMentorshipModal
            key={+isOpen}
            initialRef={initialRef}
            selectedMentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : (
          <></>
        ))}
    </>
  );
}
