import { captureNetworkError } from "@/services/error-handler";
import { MenteeType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getMentees request
 */

export type GetMenteesResponse = MenteeType[];

export const getMentees = async <T extends GetMenteesResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("mentees", options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * getSingleMentee request
 */

export type GetSingleMenteeResponse = MenteeType;

export const getSingleMentee = async <T extends GetSingleMenteeResponse>(
  mentee_id: MenteeType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`mentees/${mentee_id}`, options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};
