import { UUID } from "crypto";

import { captureNetworkError } from "@/services/error-handler";
import {
  ApplicationResponseStatusTypes,
  ApplicationType,
  MentorType,
  ServiceType,
} from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getApplications request
 * @author mentor, mentee
 */

export type GetApplicationsResponse = ApplicationType[];

export const getApplications = async <T extends GetApplicationsResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`applications`, options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * getSingleApplication request
 * @author mentor, mentee
 */

export type GetSingleApplicationResponse = ApplicationType;

export const getSingleApplication = async <
  T extends GetSingleApplicationResponse,
>(
  application_id: ApplicationType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`applications/${application_id}`, options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * applyToApplication request
 * @author mentee
 */

export type ApplyToApplicationPayload = {
  mentor_id: MentorType["id"];
  service_id: ServiceType["id"];
  request_note?: string | null;
};

export type ApplyToApplicationResponse = ApplicationType;

export const applyToApplication = async <T extends ApplyToApplicationResponse>(
  data: ApplyToApplicationPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(`applications/apply`, data, options)
    .then((res) => res)
    .catch(captureNetworkError);
};

/**
 * payApplication request
 * @author mentee
 */

export type PayApplicationResponse = {
  url: string;
};

export const payApplication = async <T extends PayApplicationResponse>(
  application_id: ApplicationType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(
      `applications/${application_id}/pay`,
      undefined,
      options,
    )
    .catch(captureNetworkError);
};

/**
 * cancelApplication request
 * @author mentee
 */

export type CancelApplicationPayload = {
  mentor_id: UUID;
  request_note?: string;
};

export type CancelApplicationResponse = ApplicationType;

export const cancelApplication = async <T extends CancelApplicationResponse>(
  application_id: ApplicationType["id"],

  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(
      `applications/${application_id}/cancel`,
      undefined,
      options,
    )
    .then((res) => res)
    .catch(captureNetworkError);
};

/**
 * respondApplication request
 * @author mentor
 */

export type RespondApplicationPayload = {
  action: ApplicationResponseStatusTypes;
  response_message?: string | null;
};

export type RespondApplicationResponse = ApplicationType;

export const respondApplication = async <T extends RespondApplicationResponse>(
  application_id: ApplicationType["id"],
  data: RespondApplicationPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(
      `applications/${application_id}/response`,
      data,
      options,
    )
    .then((res) => res);
};
