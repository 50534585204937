import { Link as ReactRouterLink } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Link as ChakraLink,
  ListItem,
  Stack,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";

import { useAuthStore } from "@/services";

export function GuidelinesPage() {
  const role = useAuthStore((state) => state.user?.role);

  return (
    <Flex
      w="100%"
      h="100%"
      p={{ base: 5, md: 20 }}
      align="flex-start"
      justify="center"
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={4}
        w="full"
        maxW="3xl"
        bg={useColorModeValue("white", "gray.900")}
        color={useColorModeValue("gray.700", "gray.200")}
        rounded="xl"
        boxShadow="lg"
        p={6}
      >
        <Flex
          pb={4}
          borderBottom={1}
          borderStyle="solid"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          justifyContent="space-between"
        >
          <Heading fontSize={{ base: "2xl", sm: "3xl" }}>Guidelines</Heading>
        </Flex>
        {role === "mentor" ? (
          <UnorderedList spacing={1}>
            <ListItem>
              <ChakraLink
                isExternal
                as={ReactRouterLink}
                to="https://saeedmirshekari.com/assets/pdfs/Checklist-omentors.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mentor&apos;s Onboarding Checklist
                <ExternalLinkIcon mx="4px" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                isExternal
                as={ReactRouterLink}
                to="https://saeedmirshekari.com/assets/pdfs/FAQs-omentors.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQs for Mentors
                <ExternalLinkIcon mx="4px" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                isExternal
                as={ReactRouterLink}
                to="https://saeedmirshekari.com/assets/pdfs/user_journey_omentors.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                User&apos;s Journey Map
                <ExternalLinkIcon mx="4px" />
              </ChakraLink>
            </ListItem>
          </UnorderedList>
        ) : (
          <UnorderedList spacing={1}>
            <ListItem>
              <ChakraLink
                isExternal
                as={ReactRouterLink}
                to="https://saeedmirshekari.com/assets/pdfs/Checklist-omentees.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mentee&apos;s Onboarding Checklist
                <ExternalLinkIcon mx="4px" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                isExternal
                as={ReactRouterLink}
                to="https://saeedmirshekari.com/assets/pdfs/FAQs-omentees.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQs for Mentees <ExternalLinkIcon mx="4px" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                isExternal
                as={ReactRouterLink}
                to="https://saeedmirshekari.com/assets/pdfs/user_journey_omentors.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                User&apos;s Journey Map
                <ExternalLinkIcon mx="4px" />
              </ChakraLink>
            </ListItem>
          </UnorderedList>
        )}
      </Stack>
    </Flex>
  );
}
