import { Box, BoxProps } from "@chakra-ui/react";
import { Star1 } from "iconsax-react";

interface RatingProps extends BoxProps {
  rating: number;
}

export function Rating({ rating, ...props }: RatingProps) {
  return (
    <Box display="flex" alignItems="center" {...props}>
      {Array(5)
        .fill("")
        .map((_, i) =>
          i < rating ? (
            <Star1 key={i} size={20} variant="Bold" color="#7bab3a" />
          ) : (
            <Star1 key={i} size={20} />
          ),
        )}
    </Box>
  );
}
