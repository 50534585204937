import { FaLinkedin } from "react-icons/fa";
import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { ListHeader, SocialButton } from "./components";

export function Footer() {
  return (
    <Box
      as="footer"
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW="5xl"
        py={{ base: 10 }}
        px={{ base: 6 }}
        borderTop={1}
        borderStyle="solid"
        borderColor={useColorModeValue("gray.200", "gray.900")}
      >
        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "2fr 1fr 1fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Image
                src="/assets/ofallon-labs-logo.png"
                alt="O'FALLON LABS LLC"
                boxSize={50}
              />
            </Box>
            <Text fontSize="sm">
              ©2024 O&apos;Fallon Labs LLC. All Rights Reserved.
            </Text>
            <Stack direction="row" spacing={6}>
              <SocialButton
                label="Linkedin"
                href="https://www.linkedin.com/company/ofallonlabs/"
              >
                <FaLinkedin />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align="flex-start">
            <ListHeader>Company</ListHeader>
            <Box as="a" href="https://saeedmirshekari.com/blog/">
              Learning Center
            </Box>
            <Box as="a" href="https://saeedmirshekari.com/contact/">
              Contact us
            </Box>
          </Stack>
          <Stack align="flex-start">
            <ListHeader>Support</ListHeader>
            <Box as="a" href="https://saeedmirshekari.com/terms_of_service">
              Terms of Service
            </Box>
            <Box as="a" href="https://saeedmirshekari.com/privacy_policy">
              Privacy Policy
            </Box>
          </Stack>
          {/* <Stack align="flex-start">
            <ListHeader>Stay up to date</ListHeader>
            <Stack direction="row">
              <Input
                placeholder="Your email address"
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300",
                }}
              />
              <IconButton
                bg={useColorModeValue("brand.400", "brand.800")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "brand.600",
                }}
                aria-label="Subscribe"
                icon={<BiMailSend />}
              />
            </Stack>
          </Stack> */}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
