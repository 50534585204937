import { ElementRef, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Container,
  Flex,
  Heading,
  IconButton,
  Input,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { SearchNormal } from "iconsax-react";

import { ConditionalLoading, EmptyState } from "@/components";
import { PublicAPI, useAuthStore } from "@/services";
import { MentorType } from "@/types";
import { filterByName } from "@/utils/filter-by-name";

import { getPublicMentorsQuery } from "../loading";

import { CreateApplicationModal } from "./create-application-modal";
import { MentorCard, MentorCardSkeleton } from "./mentor-card";

export function MentorsSection() {
  const { data: initialData } = useQuery<PublicAPI.GetMentorsResponse>(
    getPublicMentorsQuery(),
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const initialRef = useRef<ElementRef<"textarea">>(null);
  const [selected, setSelected] = useState<MentorType>();

  const { register, watch } = useForm<{ query: string }>({
    defaultValues: { query: "" },
  });

  const filteredMentors = initialData?.filter(
    (mentor) =>
      filterByName(mentor.first_name, watch("query")) ||
      filterByName(mentor.last_name, watch("query")),
  );

  const handleApply = (selectedMentor: MentorType) => {
    setSelected(selectedMentor);
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    onClose();
  };

  return (
    <Box id="latest-mentors" mt={{ base: 8, md: 14 }} pb={{ base: 20, md: 36 }}>
      <Box p={4}>
        <Stack spacing={4} as={Container} maxW="3xl" textAlign="center">
          <Heading
            as="h3"
            fontSize={{ base: "xl", sm: "2xl" }}
            fontWeight="bold"
          >
            Latest Mentors
          </Heading>
          <Stack direction="row">
            <Input
              placeholder="Search..."
              bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
              border={0}
              _focus={{
                bg: "whiteAlpha.300",
              }}
              {...register("query")}
            />
            <IconButton
              aria-label="Search"
              colorScheme="brand"
              icon={<SearchNormal />}
            />
          </Stack>
        </Stack>
        <Stack
          as={ConditionalLoading}
          isMounted={Boolean(initialData)}
          fallback={
            <>
              <MentorCardSkeleton />
              <MentorCardSkeleton />
              <MentorCardSkeleton />
            </>
          }
          direction={{ base: "column", md: "row" }}
          textAlign="center"
          justify="flex-start"
          spacing={{ base: 4, lg: 10 }}
          py={10}
          maxW="5xl"
          overflowX="auto"
        >
          {filteredMentors?.length ? (
            filteredMentors.map((mentor) => (
              <MentorCard
                key={mentor.id}
                isAuthenticated={isAuthenticated}
                mentor={mentor}
                onApply={handleApply}
              />
            ))
          ) : (
            <Flex
              maxW={{ base: "full" }}
              w="full"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              flexShrink={0}
              p={5}
              rounded="lg"
              alignItems="center"
              direction="column"
            >
              <EmptyState
                name="mentors"
                emptyText="No mentors with this information found"
              />
            </Flex>
          )}
        </Stack>
      </Box>
      {isAuthenticated && selected && (
        <CreateApplicationModal
          key={+isOpen}
          initialRef={initialRef}
          selectedMentor={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
