import { MutableRefObject } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MenteeAPI, successToast } from "@/services";
import { GoalType } from "@/types";

export function DeleteGoalModal({
  initialRef,
  finalRef,
  selectedGoal,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedGoal: GoalType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending: isSubmitting } = useMutation({
    mutationKey: [`remove-goal`, selectedGoal.id],
    mutationFn: (goal_id: Parameters<typeof MenteeAPI.deleteGoal>[0]) =>
      MenteeAPI.deleteGoal(goal_id),
  });

  const handleDeleteGoal = async () => {
    try {
      const res = await mutateAsync(selectedGoal.id);

      if (res.status === 200) {
        successToast({
          title: `Your goal removed successfully!`,
        });
        onClose();

        await queryClient.invalidateQueries({
          queryKey: ["goals"],
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Deleting This Goal:{" "}
          <Text
            as="span"
            p="2px"
            borderBottom="3px solid"
            borderBottomColor="brand.500"
          >
            {selectedGoal.title}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <Text> Are you sure?</Text>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            No
          </Button>
          <Button
            onClick={handleDeleteGoal}
            isLoading={isSubmitting}
            colorScheme="red"
            w="full"
          >
            Yes, Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
