import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Moon, Sun1 } from "iconsax-react";

import { PUBLIC_ROUTES } from "@/constants/routes";

import type { MobileProps } from "./config";
import { NotificationMenu } from "./notification-menu";
import { UserMenu } from "./user-menu";

export function MobileNav({ onOpen, ...rest }: MobileProps) {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Flex
        as={Link}
        to={PUBLIC_ROUTES.HOME}
        alignItems="center"
        gap={2}
        display={{ base: "flex", md: "none" }}
      >
        <Image
          src="/assets/ofallon-labs-logo.png"
          alt="O'FALLON LABS LLC"
          boxSize={10}
        />
        <Text
          color={useColorModeValue("black", "white")}
          textAlign={useBreakpointValue({ base: "center", lg: "left" })}
          fontFamily="heading"
          fontSize="xl"
        >
          O&apos;Mentors
        </Text>
      </Flex>
      <HStack spacing={{ base: "0", md: 2 }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="toggle theme"
          onClick={toggleColorMode}
        >
          {colorMode === "light" ? <Moon size={30} /> : <Sun1 size={30} />}
        </IconButton>
        {/* <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<Notification size={30} />}
        /> */}
        {/* <Flex alignItems="center"> */}
        <NotificationMenu />
        <UserMenu />
        {/* </Flex> */}
      </HStack>
    </Flex>
  );
}
