export const FONT_WEIGHT = {
  BOLD: "bold", // 700
  MEDIUM: "medium", // 500
  NORMAL: "normal", // 400
} as const;

export const headingStylesKeys = ["h1", "h2", "h3", "h4", "h5", "h6"];

export const headingStyles = {
  h1: {
    fontSize: "2rem",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "1.875rem",
  },
  h2: {
    fontSize: "3rem",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "4.5rem",
  },
  h3: {
    fontSize: "2.5rem",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "4rem",
  },
  h4: {
    fontSize: "2rem",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "3.5rem",
  },
  h5: {
    fontSize: "1.5rem",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "2.5rem",
  },
  h6: {
    fontSize: "1.125rem",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "2rem",
  },
} as const;

export const textStylesKeys = [
  "hero",
  ...headingStylesKeys,
  "body1",
  "body2",
  "caption1",
  "caption2",
];

export const textStyles = {
  hero: {
    fontSize: "5rem",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "7rem",
  },
  ...headingStyles,
  body1: {
    fontSize: "1rem",
    lineHeight: "1.75rem",
  },
  body2: {
    fontSize: ".875rem",
    lineHeight: "1.5rem",
  },
  caption1: {
    fontSize: ".75rem",
    lineHeight: "1.5rem",
  },
  caption2: {
    fontSize: ".625rem",
    lineHeight: "1rem",
  },
} as const;

export type TextStyles = keyof typeof textStyles;
