import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Edit, Trash } from "iconsax-react";

import { ServiceType } from "@/types";
import { dateFormatter } from "@/utils/date-formatter";

interface SingleServiceProps extends FlexProps {
  index: number;
  service: ServiceType;
  onEdit?: (selected: ServiceType) => void;
  onDelete?: (selected: ServiceType) => void;
}

export function SingleService({
  index,
  service,
  onEdit,
  onDelete,
  ...rest
}: SingleServiceProps) {
  return (
    <Flex
      w="full"
      bg={useColorModeValue("white", "gray.900")}
      rounded="md"
      p={6}
      overflow="hidden"
      borderWidth="1px"
      borderRadius="md"
      gap={4}
      direction="column"
      flexShrink={0}
      {...rest}
    >
      <HStack justify="space-between" align="center">
        <Flex justifyContent="space-between" alignItems="center" w="full">
          <Text
            color="brand.500"
            textTransform="uppercase"
            fontWeight={800}
            fontSize="sm"
            letterSpacing={1.1}
          >
            Service {index}
          </Text>
          <Badge>{service.type}</Badge>
        </Flex>

        {onEdit && onDelete && (
          <Flex gap={3}>
            <Button
              variant="ghost"
              colorScheme="gray"
              size="sm"
              onClick={() => onEdit(service)}
            >
              <Edit size={20} />
            </Button>
            <Button
              variant="ghost"
              colorScheme="red"
              size="sm"
              onClick={() => onDelete(service)}
            >
              <Trash size={20} />
            </Button>
          </Flex>
        )}
      </HStack>
      <Stack>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading
            color={useColorModeValue("gray.700", "white")}
            fontSize="2xl"
            fontFamily="body"
            textAlign="start"
          >
            {service.title}
          </Heading>
          <Text fontSize="lg" fontWeight="medium" textAlign="start">
            {service.price ? `$${service.price}` : `Free`}
          </Text>
        </Flex>
        <Text color="gray.500" textAlign="start">
          {service.description}
        </Text>
      </Stack>
      <Stack>
        <Text
          color={useColorModeValue("gray.700", "white")}
          fontFamily="body"
          textAlign="start"
        >
          Qualifications:
        </Text>
        <Text color="gray.500" textAlign="start">
          {service.qualifications}
        </Text>
      </Stack>
      {service.calendly_event && (
        <Stack>
          <Link to={service.calendly_event}>Linked Calendly Event</Link>
        </Stack>
      )}
      {service?.created_at && (
        <Flex
          direction={{ base: "column", lg: "row" }}
          mt={6}
          gap={4}
          align={{ base: "flex-start", lg: "center" }}
          justify="space-between"
        >
          <Stack direction="column" spacing={0} fontSize="sm">
            <Text fontWeight={600}>Created at: </Text>
            <Text color="gray.500">
              {dateFormatter(service.created_at, {
                dateStyle: "medium",
                timeStyle: "short",
              })}
            </Text>
          </Stack>
        </Flex>
      )}
    </Flex>
  );
}
