import {
  type LoaderFunctionArgs,
  type Params,
  redirect,
} from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { CALENDLY_ROUTES, DASHBOARD_ROUTES } from "@/constants/routes";
import { UserAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

import { protectedLoader } from "../protected";

export const postCalendlyOAuthCallbackQuery = (code: string) => ({
  queryKey: ["calendly-oauth-callback", code],
  queryFn: () => UserAPI.connectToCalendlyOAuthCallback({ code }),
});

export const calendlyLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const params = args.params as Params<"return_url">;

      const url = new URL(args.request.url);
      const searchParams = new URLSearchParams(url.search);

      const code = searchParams.get("code");

      switch (params.return_url) {
        case CALENDLY_ROUTES.CONNECT_REAUTH:
          searchParams.set("success", "false");
          return redirect(
            `${DASHBOARD_ROUTES.ACCOUNT}?${searchParams.toString()}`,
          );

        case CALENDLY_ROUTES.CONNECT_RETURN:
          if (code) {
            const query = postCalendlyOAuthCallbackQuery(code);

            queryClient.getQueryData(query.queryKey) ??
              (await queryClient.fetchQuery(query));

            return redirect(
              `${DASHBOARD_ROUTES.ACCOUNT}?${searchParams.toString()}`,
            );
          }

          return redirect(CALENDLY_ROUTES.CONNECT_REAUTH);

        default:
          return redirect(DASHBOARD_ROUTES.HOME);
      }
    } catch (error) {
      captureNetworkError(error);
    }
  };
