export const PUBLIC_PREFIX = "/";
export const AUTH_PREFIX = "/auth";
export const DASHBOARD_PREFIX = "/dashboard";
export const STRIPE_PREFIX = "/stripe";
export const CALENDLY_PREFIX = "/calendly";

export const PUBLIC_ROUTES = {
  HOME: PUBLIC_PREFIX,
};

export const AUTH_ROUTES = {
  LOGIN: `${AUTH_PREFIX}/login`,
  REGISTER: `${AUTH_PREFIX}/register`,
  FORGET: `${AUTH_PREFIX}/forget`,
  LOGOUT: `${AUTH_PREFIX}/logout`,
};

export const DASHBOARD_ROUTES = {
  HOME: DASHBOARD_PREFIX,
  // mentee's routes
  MENTORS: `${DASHBOARD_PREFIX}/mentors`,
  BACKGROUND: `${DASHBOARD_PREFIX}/background`,
  GOALS: `${DASHBOARD_PREFIX}/goals`,

  // mentor's routes
  MENTEES: `${DASHBOARD_PREFIX}/mentees`,
  APPLICATIONS: `${DASHBOARD_PREFIX}/applications`,
  SERVICES: `${DASHBOARD_PREFIX}/services`,

  // shared routes
  NOTIFICATIONS: `${DASHBOARD_PREFIX}/notifications`,
  MENTORSHIPS: `${DASHBOARD_PREFIX}/mentorships`,
  GUIDELINES: `${DASHBOARD_PREFIX}/guidelines`,
  ACCOUNT: `${DASHBOARD_PREFIX}/account`,
};

export const STRIPE_ROUTES = {
  CONNECT_REAUTH: "connect-reauth",
  CONNECT_RETURN: "connect-return",
  CHECKOUT_CANCEL: "checkout-cancel",
  CHECKOUT_SUCCESS: "checkout-success",
};

export const CALENDLY_ROUTES = {
  CONNECT_REAUTH: "connect-reauth",
  CONNECT_RETURN: "connect-return",
};
