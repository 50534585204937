import { LoaderFunctionArgs } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { MentorAPI, UserAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

export const getServicesQuery = () => ({
  queryKey: ["services"],
  queryFn: MentorAPI.getServices,
});

export const getCalendlyEventsQuery = () => ({
  queryKey: ["calendly-events"],
  queryFn: UserAPI.getMyCalendlyEvents,
});

export const servicesLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const servicesQuery = getServicesQuery();
      queryClient.getQueryData(servicesQuery.queryKey) ??
        (await queryClient.fetchQuery(servicesQuery));

      const calendlyEventsQuery = getServicesQuery();
      queryClient.getQueryData(calendlyEventsQuery.queryKey) ??
        (await queryClient.fetchQuery(calendlyEventsQuery));

      return null;
    } catch (error) {
      captureNetworkError(error);
    }
  };
