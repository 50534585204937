import { Box, Container, Image, Stack } from "@chakra-ui/react";

export function HeroSection() {
  return (
    <Box py={4}>
      <Stack
        spacing={{ base: 4, md: 10, lg: 15 }}
        as={Container}
        maxW="3xl"
        textAlign="center"
      >
        <Image
          src="/assets/hero-banner.svg"
          alt="Premium 1-on-1 Mentorship for Data Science Careers"
          pointerEvents="none"
        />
        <Image
          src="/assets/hero-mentors.png"
          alt="Beta Mentors"
          pointerEvents="none"
        />
        <Image
          src="/assets/hero-text.svg"
          alt="Beta Mentors"
          mt={5}
          pointerEvents="none"
        />
      </Stack>
    </Box>
  );
}
