import { Button, ButtonProps, useColorModeValue } from "@chakra-ui/react";

interface SocialButtonProps extends ButtonProps {
  children: React.ReactNode;
  label: string;
  href: string;
}

export function SocialButton({
  children,
  label,
  href,
  ...props
}: SocialButtonProps) {
  return (
    <Button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded="full"
      cursor="pointer"
      as="a"
      href={href}
      target="_blank"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background 0.3s ease"
      p={0}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
