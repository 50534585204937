import { FiX } from "react-icons/fi";
import {
  Box,
  Container,
  Flex,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

export function HeaderBanner() {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });

  return (
    isOpen && (
      <Box as="section" borderBottomWidth="1px" bg="brand.400">
        <Container py={{ base: "2", md: "2.5" }} maxW="full">
          <Stack
            direction="row"
            spacing={{ base: "3", md: "4" }}
            justify="space-between"
            align="center"
            w="full"
            color="gray.800"
          >
            <Flex
              flex={1}
              justifyContent="center"
              pl={{ base: 0, md: "3.75rem" }}
            >
              <Text fontWeight="medium" fontSize={{ base: "xs", sm: "md" }}>
                Beta testing ends on{" "}
                <Text
                  as="b"
                  p="2px"
                  borderBottom="3px solid"
                  borderBottomColor="brand.500"
                >
                  March 29, 2024
                </Text>
              </Text>
            </Flex>
            <IconButton
              icon={<FiX />}
              variant="tertiary"
              aria-label="Close banner"
              onClick={onToggle}
            />
          </Stack>
        </Container>
      </Box>
    )
  );
}
