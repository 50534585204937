export const colors = {
  brand: {
    50: "#eefbe3",
    100: "#d8edc1",
    200: "#c3df9e",
    300: "#afd279",
    400: "#9cc554",
    500: "#7bab3a",
    600: "#5a852c",
    700: "#3b5f1d",
    800: "#1f390e",
    900: "#061400",
    background: "#EBF2DF",
  },
  button: {
    green: "#779B36",
  },
  error: {
    100: "#db2323",
    80: "#E66565",
    60: "#ED9191",
    40: "#F4BDBD",
    20: "#FBE9E9",
    5: "#FDF4F4",
  },
  success: {
    100: "#14B823",
    80: "#5BCD65",
    60: "#89DB91",
    40: "#B9EABD",
    20: "#E7F8E9",
    5: "#F3FBF4",
  },
  warning: {
    100: "#F1C21B",
    80: "#F3CC41",
    60: "#F8E08D",
    40: "#FAEBB3",
    20: "#FCF3D1",
    5: "#FEFCF4",
  },
} as const;

export type Colors = typeof colors;
