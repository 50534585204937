import { Button, Heading, Stack, Text } from "@chakra-ui/react";

interface EmptyStateProps {
  name?: string;
  emptyText?: string;
  description?: string;
  showWelcome?: boolean;
  onClick?: () => void;
}

export function EmptyState({
  name = "item",
  emptyText = `You don’t have any ${name}s yet`,
  description,
  showWelcome,
  onClick,
}: EmptyStateProps) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={4}
      p={10}
      borderRadius={8}
    >
      <Heading size="md" fontWeight="medium">
        {emptyText}
      </Heading>
      {description && <Text fontSize="sm">{description}</Text>}
      {showWelcome && <Text fontSize="lg">Welcome 👋🏼 Let’s get started.</Text>}
      {onClick && (
        <Button colorScheme="brand" size="md" mt={2} onClick={onClick}>
          Add your first {name}
        </Button>
      )}
    </Stack>
  );
}
