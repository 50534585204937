import { NotificationType, NotificationTypes } from "@/types";

import { DASHBOARD_ROUTES } from "./routes";

export const notificationTypeMapping = (
  notifier?: NotificationType["notifier"],
): Record<
  NotificationTypes,
  { label: string; link: string; icon: string }
> => ({
  new_user: {
    label: "Welcome to O'Mentors!",
    link: DASHBOARD_ROUTES.GUIDELINES,
    icon: "🎉",
  },
  new_application: {
    label: notifier
      ? `${notifier} has applied to you!`
      : "You just received a new application!",
    link: DASHBOARD_ROUTES.APPLICATIONS,
    icon: "📋",
  },
  respond_application: {
    label: notifier
      ? `${notifier} has responded to you!`
      : "A mentor has responded to your application! ",
    link: DASHBOARD_ROUTES.APPLICATIONS,
    icon: "📋",
  },
  start_mentorship: {
    label: notifier
      ? `The ${notifier} mentorship has just started!`
      : "Your new mentorship has just started!",
    link: DASHBOARD_ROUTES.MENTORSHIPS,
    icon: "🎓",
  },
  update_mentorship: {
    label: notifier
      ? `The ${notifier} mentorship has updated!`
      : "Your mentorship status is updated!",
    link: DASHBOARD_ROUTES.MENTORSHIPS,
    icon: "🎓",
  },
});
