import { captureNetworkError } from "@/services/error-handler";
import { BackgroundType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getBackground request
 */

export type GetBackgroundResponse = BackgroundType;

export const getBackground = async <T extends GetBackgroundResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("background", options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * editBackground request
 */

export type EditBackgroundPayload = Pick<
  BackgroundType,
  "education" | "job" | "skills" | "tools"
>;

export type EditBackgroundResponse = BackgroundType;

export const editBackground = async <T extends EditBackgroundResponse>(
  data: EditBackgroundPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .put<T, SuccessResponseType<T>>(`background`, data, options)
    .then((res) => res)
    .catch(captureNetworkError);
};
