import { MutableRefObject, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";

import { EmptyState, LoadingState } from "@/components";
import { SingleService } from "@/pages/dashboard/services/single-service";
import { SharedAPI, successToast } from "@/services";
import { MentorType, ServiceType } from "@/types";
import { getFullName } from "@/utils/get-user-name";

export function CreateApplicationModal({
  initialRef,
  finalRef,
  selectedMentor,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLTextAreaElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedMentor: MentorType;
} & Omit<ModalProps, "children">) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SharedAPI.ApplyToApplicationPayload>({
    defaultValues: { mentor_id: selectedMentor.id, request_note: null },
  });

  const [selectedService, setSelectedService] = useState<
    MentorType["services"][number]
  >(selectedMentor?.services?.[0]);

  const handleSubmitApplication = handleSubmit(
    async ({ mentor_id, request_note }) => {
      try {
        const res = await SharedAPI.applyToApplication({
          mentor_id,
          service_id: selectedService.id,
          request_note: request_note || null,
        });
        if (res.status === 200) {
          successToast({
            title: `The application sent successfully!`,
          });
          onClose();
        }
      } catch (error) {
        console.log({ error });
      }
    },
  );

  const { ref: resMsgInputRef, ...resMsgInputRegister } =
    register("request_note");

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      // isCentered
      size={{ base: "full", sm: "2xl" }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmitApplication} noValidate>
        <ModalHeader>
          Apply to{" "}
          <Text
            as="span"
            p="2px"
            borderBottom="3px solid"
            borderBottomColor="brand.500"
          >
            {getFullName(selectedMentor)}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          {selectedMentor ? (
            <Flex direction="column" gap={6}>
              {selectedMentor.services.length ? (
                <Stack
                  direction={{ base: "column", md: "row" }}
                  textAlign="center"
                  justify="flex-start"
                  spacing={{ base: 4, lg: 10 }}
                  overflowX="auto"
                >
                  {selectedMentor.services.map((service, index) => (
                    <SingleService
                      key={service.id}
                      index={index + 1}
                      service={service as ServiceType}
                      cursor="pointer"
                      onClick={() => setSelectedService(service)}
                      borderColor={
                        service.id === selectedService?.id
                          ? "brand.500"
                          : "inherit"
                      }
                      bg={
                        service.id === selectedService?.id
                          ? "gray.50"
                          : "inherit"
                      }
                    />
                  ))}
                </Stack>
              ) : (
                <EmptyState
                  name="services"
                  emptyText="They don't added any services yet."
                />
              )}
            </Flex>
          ) : (
            <LoadingState />
          )}
          <FormControl
            id="request_note"
            isInvalid={Boolean(errors.request_note)}
          >
            <FormLabel htmlFor="request_note" fontWeight="normal">
              Request Note
            </FormLabel>
            <Textarea
              isDisabled={isSubmitting}
              {...resMsgInputRegister}
              ref={(e) => {
                resMsgInputRef(e);

                // it need to support focus on first input
                if (e) {
                  initialRef.current = e;
                }
              }}
            />
            {errors.request_note && (
              <FormErrorMessage>{errors.request_note.message}</FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            w="full"
            bg={useColorModeValue("#151f21", "gray.700")}
            color="white"
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
