import { Box } from "@chakra-ui/react";

import { LoadingState } from "@/components";

export function LoadingPage() {
  return (
    <Box height="100vh" width="100vw">
      <LoadingState as="main" />
    </Box>
  );
}
