import { captureNetworkError } from "@/services/error-handler";
import { GoalType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getGoals request
 */

export type GetGoalsResponse = GoalType[];

export const getGoals = async <T extends GetGoalsResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("goals", options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * getSingleGoal request
 */

export type GetSingleGoalResponse = GoalType;

export const getSingleGoal = async <T extends GetSingleGoalResponse>(
  goal_id: GoalType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`goals/${goal_id}`, options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * createGoal request
 */

export type CreateGoalPayload = Pick<
  GoalType,
  | "title"
  | "description"
  | "expectations"
  | "expected_timeline"
  | "meeting_frequency"
  | "focus_hpw"
>;

export type CreateGoalResponse = GoalType;

export const createGoal = async <T extends CreateGoalResponse>(
  data: CreateGoalPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>("goals", data, options)
    .then((res) => res)
    .catch(captureNetworkError);
};

/**
 * editGoal request
 */

export type EditGoalPayload = CreateGoalPayload;

export type EditGoalResponse = GoalType;

export const editGoal = async <T extends EditGoalResponse>(
  goal_id: GoalType["id"],
  data: EditGoalPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .put<T, SuccessResponseType<T>>(`goals/${goal_id}`, data, options)
    .then((res) => res)
    .catch(captureNetworkError);
};

/**
 * deleteGoal request
 */

export type DeleteGoalPayload = CreateGoalPayload;

export type DeleteGoalResponse = GoalType;

export const deleteGoal = async <T extends DeleteGoalResponse>(
  goal_id: GoalType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .delete<T, SuccessResponseType<T>>(`goals/${goal_id}`, options)
    .then((res) => res)
    .catch(captureNetworkError);
};
