import Cookies from "js-cookie";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import { config } from "@/constants";
import { UserType } from "@/types";

// Define the interface of the Cart state
interface State {
  isAuthenticated: boolean;
  accessToken: string | undefined;
  user: UserType | undefined;
}

// Define the interface of the actions that can be performed in the Cart
interface Actions {
  setAccessToken: (accessToken: string | undefined) => void;
  setUser: (user: UserType) => void;
  // set tokens on the app start
  init: () => void;
  clearTokens: () => void;
}

// Initialize a default state
const INITIAL_STATE: State = {
  isAuthenticated: false,
  accessToken: undefined,
  user: undefined,
};

// Create the store with Zustand, combining the status interface and actions
export const useAuthStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      isAuthenticated: INITIAL_STATE.isAuthenticated,
      accessToken: INITIAL_STATE.accessToken,
      user: INITIAL_STATE.user,

      setAccessToken: async (accessToken: string | undefined) => {
        Cookies.set(config.AUTH_COOKIE_KEY, accessToken || "");
        set(() => ({ accessToken, isAuthenticated: true }));
      },
      setUser: (user) => {
        set(() => ({ user }));
      },
      init: async () => {
        const { setAccessToken } = get();
        const token = Cookies.get(config.AUTH_COOKIE_KEY);
        if (typeof token === "string") {
          setAccessToken(token);
        }
      },
      clearTokens: () => {
        set(INITIAL_STATE);
        Cookies.remove(config.AUTH_COOKIE_KEY);
        localStorage.clear();
      },
    }),
    {
      name: "auth",
      // skipHydration: true
    },
  ),
);
