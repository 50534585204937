import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";

import { AUTH_ROUTES } from "@/constants/routes";
import { AuthAPI, successToast } from "@/services";
import { RoleTypes } from "@/types";

function RadioCard({
  children,
  ...props
}: { children: React.ReactNode } & UseRadioProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" flex={1}>
      <input {...input} />
      <Box
        {...checkbox}
        display="flex"
        justifyContent="center"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        _checked={{
          bg: "gray.100",
          // color: "white",
        }}
        px={5}
        py={3}
        textTransform="capitalize"
      >
        {children}
      </Box>
    </Box>
  );
}

export function RegisterPage() {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AuthAPI.RegisterPayload>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      role: "mentee",
    },
  });

  const handleSubmitRegister = handleSubmit(
    async ({ first_name, last_name, email, password, role }) => {
      try {
        const res = await AuthAPI.register({
          first_name,
          last_name,
          email,
          password,
          role,
        });

        if (res.status === 201) {
          successToast({
            title: "Your account created successfully!",
            description: "Please login with your credentials",
          });
          navigate(AUTH_ROUTES.LOGIN);
        }
      } catch (error) {
        console.log({ error });
      }
    },
  );

  const options = ["mentee", "mentor"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "role",
    defaultValue: "mentee",
    onChange: (selected: RoleTypes) => setValue("role", selected),
  });

  const group = getRootProps();

  return (
    <Flex
      as="form"
      onSubmit={handleSubmitRegister}
      w="100%"
      h="100%"
      p={{ base: 0, sm: 20 }}
      align="center"
      justify="center"
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx="auto" minW={{ base: "none", sm: "lg" }} py={12}>
        <Stack align="center">
          <Heading fontSize="4xl" textAlign="center">
            Register New Account
          </Heading>
        </Stack>
        <Box
          rounded="lg"
          bg={useColorModeValue("white", "gray.700")}
          boxShadow="lg"
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="first_name" isInvalid={Boolean(errors.first_name)}>
              <FormLabel>First Name</FormLabel>
              <Input
                type="first_name"
                {...register("first_name", {
                  required: "You must provide your first name to register",
                })}
              />
              {errors.first_name && (
                <FormErrorMessage>{errors.first_name.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl id="last_name" isInvalid={Boolean(errors.last_name)}>
              <FormLabel>Last Name</FormLabel>
              <Input
                type="last_name"
                {...register("last_name", {
                  required: "You must provide your last name to register",
                })}
              />
              {errors.last_name && (
                <FormErrorMessage>{errors.last_name.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl id="email" isInvalid={Boolean(errors.email)}>
              <FormLabel>Email Address</FormLabel>
              <Input
                type="email"
                {...register("email", {
                  required: "You must provide your email to register",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl id="password" isInvalid={Boolean(errors.password)}>
              <FormLabel>Password</FormLabel>

              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: "You must provide a password to register",
                    minLength: {
                      value: 6,
                      message: "Password should be at least 6 characters",
                    },
                  })}
                />
                <InputRightElement h="full">
                  <Button
                    variant="ghost"
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.password && (
                <FormErrorMessage>{errors.password.message}</FormErrorMessage>
              )}
            </FormControl>
            <HStack {...group} w="100%">
              {options.map((value) => (
                <RadioCard key={value} {...getRadioProps({ value })}>
                  {value}
                </RadioCard>
              ))}
            </HStack>
            <Stack spacing={10} pt={2}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                size="lg"
                bg="brand.400"
                color="white"
                _hover={{
                  bg: "brand.500",
                }}
              >
                Sign up
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align="center">
                Already a user?{" "}
                <Text as={Link} to={AUTH_ROUTES.LOGIN} color="brand.400">
                  Login
                </Text>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
