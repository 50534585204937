import { QueryCache, QueryClient } from "@tanstack/react-query";
import { createBrowserHistory } from "history";

import { config } from "@/constants";

import { toast } from "./services";

export const queryClient: QueryClient = (() =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: config.isProduction,
        refetchOnWindowFocus: config.isProduction,
        staleTime: config.API_STALE_TIME, // time to refresh data
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        toast({
          status: "error",
          title: error?.message || "Error! Please try again",
        });
      },
    }),
  }))();

export const browserHistory = createBrowserHistory();
