import { MutableRefObject } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { SharedAPI, successToast } from "@/services";
import { MentorshipType } from "@/types";

export function NextMentorshipModal({
  initialRef,
  finalRef,
  selectedMentorship,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedMentorship: MentorshipType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending: isSubmitting } = useMutation({
    mutationKey: [`next-mentorship`, selectedMentorship.id],
    mutationFn: (
      mentorship_id: Parameters<typeof SharedAPI.performActionOnMentorship>[0],
    ) =>
      SharedAPI.performActionOnMentorship(mentorship_id, {
        action: "next",
      }),
  });

  const handleCancelMentorship = async () => {
    try {
      const res = await mutateAsync(selectedMentorship.id);

      if (res.status === 200) {
        successToast({
          title: `Your mentorship is moved to the next phase successfully!`,
        });
        onClose();

        await queryClient.invalidateQueries({
          queryKey: ["mentorships"],
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Moving to The Next Mentorship Phase</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <Text>
            {" "}
            Do you confirm to move this mentorship to the next phase?
          </Text>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={handleCancelMentorship}
            isLoading={isSubmitting}
            w="full"
            bg={useColorModeValue("#151f21", "gray.900")}
            color="white"
          >
            Yes, I Do
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
