import { LinkIcon } from "@chakra-ui/icons";
import {
  Badge,
  Button,
  Flex,
  Heading,
  SkeletonText,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { Forbidden } from "iconsax-react";

import { CustomAvatar } from "@/components";
import { SocialButton } from "@/components/footer/components";
import { MentorshipStatusTypes, MentorshipType, RoleTypes } from "@/types";
import { dateFormatter } from "@/utils/date-formatter";
import { getFullName } from "@/utils/get-user-name";
import { getRelativeTimeString } from "@/utils/relative-date";

import { MentorshipProgress } from "./mentorship-progress";

const mentorshipStatusColorMap: Record<MentorshipStatusTypes, string> = {
  quitted: "red",
  progress: "blue",
  done: "green",
};

interface SingleMentorshipProps {
  role: RoleTypes;
  mentorship: MentorshipType;
  onDetail: (mentorship: MentorshipType) => void;
  onQuit: (mentorship: MentorshipType) => void;
  onNext: (mentorship: MentorshipType) => void;
  onFinish: (mentorship: MentorshipType) => void;
}

export function SingleMentorship({
  role,
  mentorship,
  onDetail,
  onQuit,
  onNext,
  onFinish,
}: SingleMentorshipProps) {
  const isMentee = role === "mentee";
  const isProgress = mentorship.status === "progress";
  const authorInfo = isMentee ? mentorship?.mentor : mentorship?.mentee;

  const CTABtnBg = useColorModeValue("#151f21", "gray.700");
  const labelColor = useColorModeValue("gray.700", "gray.400");
  return (
    <Flex
      direction="column"
      maxW={{ base: "full", md: "275px" }}
      w="full"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      flexShrink={0}
      p={5}
      bg={useColorModeValue("white", "gray.900")}
      rounded="lg"
      justifyContent="center"
      alignItems="center"
    >
      <CustomAvatar name={getFullName(authorInfo!)} />
      <SkeletonText
        w="full"
        mb={4}
        noOfLines={2}
        lineHeight={1}
        skeletonHeight="5"
        display="flex"
        flexDirection="column-reverse"
        alignItems="center"
        gap={2}
        fadeDuration={1}
        isLoaded={Boolean(authorInfo)}
      >
        <Heading
          fontSize="2xl"
          fontFamily="body"
          mb={2}
          onClick={() => onDetail(mentorship)}
          cursor="pointer"
        >
          {getFullName(authorInfo!)}
        </Heading>
        <Text fontWeight={600} color="gray.500">
          {authorInfo!.email}
        </Text>
      </SkeletonText>
      <Badge colorScheme={mentorshipStatusColorMap[mentorship.status]}>
        {mentorship.status.toUpperCase()}
      </Badge>
      <Flex direction="column">
        <Text
          fontSize="xs"
          fontWeight="bold"
          textAlign="center"
          color={useColorModeValue("gray.700", "gray.400")}
          mt={4}
        >
          Mentorship type:
        </Text>
        <Text
          as="q"
          fontSize="xs"
          fontStyle="italic"
          textAlign="center"
          color={useColorModeValue("gray.700", "gray.400")}
          mb="auto"
          noOfLines={2}
        >
          {mentorship.type}
        </Text>
      </Flex>
      {mentorship.status === "progress" &&
        mentorship.type === "subscription" && (
          <>
            {mentorship.progress && (
              <MentorshipProgress progress={mentorship.progress} />
            )}
          </>
        )}
      <Text
        mt="auto"
        fontSize="xs"
        fontWeight="bold"
        textAlign="center"
        color={labelColor}
      >
        Started at:
      </Text>
      <Text fontSize="xs" textAlign="center" colorScheme="blue" noOfLines={1}>
        {`${dateFormatter(mentorship.created_at, {
          dateStyle: "medium",
        })} (${getRelativeTimeString(mentorship.created_at)})`}
      </Text>
      {mentorship.ended_at && (
        <>
          <Text
            mt="auto"
            fontSize="xs"
            fontWeight="bold"
            textAlign="center"
            color={labelColor}
          >
            {`${mentorship.status === "quitted" ? "Quitted" : "Ended"} at:`}
          </Text>
          <Text
            fontSize="xs"
            textAlign="center"
            colorScheme="blue"
            noOfLines={1}
          >
            {`${dateFormatter(mentorship.ended_at, {
              dateStyle: "medium",
            })} (${getRelativeTimeString(mentorship.ended_at)})`}
          </Text>
        </>
      )}

      <Stack mt={8} direction="row" spacing={4} w="full" maxW="md">
        {isProgress ? (
          isMentee ? (
            <Flex gap={4} w="full" maxW="md">
              {mentorship.mentor?.external_link && (
                <SocialButton
                  href={mentorship.mentor?.external_link}
                  label="Link"
                  boxSize="40px"
                  _focus={{
                    bg: "gray.200",
                  }}
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                  }}
                >
                  <Tooltip
                    label="Contact Link"
                    placement="top"
                    hasArrow
                    openDelay={500}
                    arrowSize={15}
                  >
                    <LinkIcon />
                  </Tooltip>
                </SocialButton>
              )}
              <Button
                isDisabled={!mentorship.booking_url}
                as="a"
                href={mentorship.booking_url || "#"}
                flex={1}
                rounded="full"
                bg={CTABtnBg}
                color="white"
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Book a Session
              </Button>
            </Flex>
          ) : (
            <Flex gap={4} w="full" maxW="md">
              <Button
                variant="outline"
                colorScheme="red"
                rounded="full"
                cursor="pointer"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                transition="background 0.3s ease"
                p={0}
                onClick={() => onQuit(mentorship)}
              >
                <Tooltip
                  label="Quit from Mentorship"
                  placement="top"
                  hasArrow
                  openDelay={500}
                  arrowSize={15}
                >
                  <Forbidden size={20} />
                </Tooltip>
              </Button>
              {mentorship.mentee?.external_link && (
                <SocialButton
                  href={mentorship.mentee?.external_link}
                  label="Link"
                  boxSize="40px"
                  _focus={{
                    bg: "gray.200",
                  }}
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                  }}
                >
                  <Tooltip
                    label="Contact Link"
                    placement="top"
                    hasArrow
                    openDelay={500}
                    arrowSize={15}
                  >
                    <LinkIcon />
                  </Tooltip>
                </SocialButton>
              )}
              {mentorship.type === "session" ||
              mentorship.progress === "phase3" ? (
                <Button
                  flex={1}
                  rounded="full"
                  onClick={() => onFinish(mentorship)}
                  bg={CTABtnBg}
                  color="white"
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                  }}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  flex={1}
                  rounded="full"
                  onClick={() => onNext(mentorship)}
                  bg={CTABtnBg}
                  color="white"
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                  }}
                >
                  Next
                </Button>
              )}
            </Flex>
          )
        ) : (
          <Button isDisabled flex={1} rounded="full">
            Done
          </Button>
        )}
      </Stack>
    </Flex>
  );
}
