import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";

import { Footer, Header } from "@/components";
import { HeaderBanner } from "@/components/header-banner";
import { queryClient } from "@/index";

export function MainLayout() {
  const [queryClientState] = useState(() => queryClient);

  return (
    <QueryClientProvider client={queryClientState}>
      <Flex direction="column" w="100%" h="100%" flex={1}>
        <HeaderBanner />
        <Header />
        <Flex
          as="main"
          direction="column"
          w="100%"
          h="100%"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <Outlet />
        </Flex>
        <Footer />
      </Flex>
    </QueryClientProvider>
  );
}
