import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";

import { queryClient } from "@/index";

import { MobileNav } from "./components/mobile-nav";
import { SidebarContent } from "./components/sidebar-content";

export function DashboardLayout() {
  const [queryClientState] = useState(() => queryClient);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <QueryClientProvider client={queryClientState}>
      <Box minH="100vh" bg={useColorModeValue("gray.50", "gray.800")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          closeOnEsc
          closeOnOverlayClick
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} position="sticky" top={0} zIndex={1} />
        <Box ml={{ base: 0, md: 60 }}>
          <Flex
            as="main"
            direction="column"
            w="100%"
            h="100%"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Outlet />
          </Flex>
        </Box>
      </Box>
    </QueryClientProvider>
  );
}
