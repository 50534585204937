import { redirect } from "react-router-dom";

import { DASHBOARD_ROUTES, PUBLIC_ROUTES } from "@/constants/routes";
import { useAuthStore } from "@/services";

export async function loginLoader() {
  const { isAuthenticated, user } = useAuthStore?.getState() || {};

  if (isAuthenticated) {
    if (user?.role === "mentee") {
      return redirect(PUBLIC_ROUTES.HOME);
    }
    return redirect(DASHBOARD_ROUTES.HOME);
  }
  return true;
}
