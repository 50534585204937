import { captureNetworkError } from "@/services/error-handler";
import { NotificationType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getNotificationList request
 */

export type GetNotificationList = NotificationType[];

export const getNotificationList = async <T extends GetNotificationList>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("notifications", options)
    .then((res) => res.data);
};

/**
 * seenNotification request
 */

export type SeenNotificationResponse = NotificationType;

export const seenNotification = async <T extends SeenNotificationResponse>(
  notification_id: NotificationType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(
      `notifications/${notification_id}/seen`,
      options,
    )
    .then((res) => res.data)
    .catch(captureNetworkError);
};
