import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useAuthStore } from "@/services/store";

import { Arrow } from "./arrow";
import { HeroSection, MentorsSection } from "./components";

export function HomePage() {
  const { isAuthenticated, user: { first_name } = {} } = useAuthStore();

  return (
    <Flex w="100%" h="100%" direction="column" align="center" justify="center">
      <Container maxW="5xl" py={{ base: 10, md: 18 }}>
        <Stack
          as={Container}
          maxW="5xl"
          textAlign="center"
          spacing={{ base: 8, md: 14 }}
        >
          {isAuthenticated ? (
            <Heading
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
              mt={{ base: "2.5rem", md: "8rem" }}
            >
              Welcome, {first_name || "User"}! 🔐
              <br />
              <Text as="span" color="brand.400">
                Find 1-on-1 Mentorships
              </Text>
            </Heading>
          ) : (
            <>
              <HeroSection />
              {/* <Heading
                fontWeight={600}
                fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
                lineHeight="110%"
              >
                1-on-1 Mentorship 🔒
                <br />
                <Text as="span" color="brand.400">
                  Data Science Careers
                </Text>
              </Heading> */}
            </>
          )}
          <Text color="gray.500">
            All our mentors are highly qualified and caring individuals who have
            &apos;been there, done that!
          </Text>
          <Stack
            direction="column"
            spacing={3}
            align="center"
            alignSelf="center"
            position="relative"
          >
            <Button
              as="a"
              href="#latest-mentors"
              colorScheme="brand"
              rounded="full"
              px={6}
            >
              Get Started
            </Button>
            <Box>
              <Icon
                as={Arrow}
                color={useColorModeValue("gray.800", "gray.300")}
                w={71}
                position="absolute"
                right="-65px"
                top="10px"
              />
              <Text
                fontSize="lg"
                fontFamily="Caveat"
                position="absolute"
                right="-125px"
                top="-15px"
                transform="rotate(10deg)"
              >
                Apply Now!
              </Text>
            </Box>
          </Stack>
          {isAuthenticated && (
            <Flex
              direction={{ base: "column", sm: "row" }}
              alignItems="center"
              gap={{ base: 1, sm: 2 }}
              justifyContent="center"
            >
              <Text fontSize="lg">Need help? Tutorials for: </Text>
              <Box>
                <Button
                  as="a"
                  href="https://saeedmirshekari.com/assets/pdfs/mentee-process-232024.pdf"
                  target="_blank"
                  variant="link"
                  color="brand.600"
                  fontSize="lg"
                >
                  Mentees
                </Button>
                {" / "}
                <Button
                  as="a"
                  href="https://saeedmirshekari.com/assets/pdfs/mentor-process-232024.pdf"
                  target="_blank"
                  variant="link"
                  color="brand.600"
                  fontSize="lg"
                >
                  Mentors
                </Button>
              </Box>
            </Flex>
          )}
          {/* <Text fontSize="lg">
            Beta testing ends on{" "}
            <Text
              as="span"
              p="2px"
              borderBottom="3px solid"
              borderBottomColor="brand.500"
            >
              March 29, 2024
            </Text>
          </Text> */}
          <MentorsSection />
        </Stack>
      </Container>
    </Flex>
  );
}
