import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notification } from "iconsax-react";

import { EmptyState } from "@/components";
import { notificationTypeMapping } from "@/constants/mappings";
import { DASHBOARD_ROUTES } from "@/constants/routes";
import { getNotificationListQuery } from "@/pages/dashboard/notifications";
import { NotificationAPI } from "@/services";
import { NotificationType } from "@/types";
import { getRelativeTimeString } from "@/utils/relative-date";

interface SingleMenuNotificationProps {
  notification: NotificationType;
  onClick: (selected: NotificationType) => void;
}

function SingleMenuNotification({
  notification,
  onClick,
}: SingleMenuNotificationProps) {
  const notificationMap = notificationTypeMapping(notification.notifier)[
    notification.type
  ];

  return (
    <MenuItem
      onClick={() => onClick(notification)}
      bg={useColorModeValue(
        notification.status === "unread" ? "white" : "gray.50",
        notification.status === "unread" ? "gray.900" : "gray.800",
      )}
      _hover={{
        bgColor: useColorModeValue("gray.100", "gray.700"),
      }}
      p={4}
      gap={4}
      alignItems="center"
      justifyContent="space-between"
      opacity={useColorModeValue(
        notification.status === "unread" ? "100%" : "70%",
        notification.status === "unread" ? "100%" : "60%",
      )}
    >
      <Box
        boxSize={35}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={useColorModeValue("brand.50", "gray.700")}
        borderRadius={100}
      >
        {notificationMap.icon}
      </Box>
      <Box flex={1}>
        <Text _firstLetter={{ textTransform: "uppercase" }}>
          {notificationMap.label}
        </Text>
        <Text fontSize="xs">
          {getRelativeTimeString(notification.timestamp)}
        </Text>
      </Box>
    </MenuItem>
  );
}

function MoreNotifications({ length }: { length: number }) {
  return (
    <>
      <MenuDivider m={0} />
      <MenuItem
        as={Link}
        to={DASHBOARD_ROUTES.NOTIFICATIONS}
        p={4}
        bg={useColorModeValue("white", "gray.900")}
        _hover={{
          bgColor: useColorModeValue("gray.100", "gray.700"),
        }}
        justifyContent="center"
      >
        See all notifications {`(${length})`}
      </MenuItem>
    </>
  );
}

export function NotificationMenu() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: initialData, isLoading } =
    useQuery<NotificationAPI.GetNotificationList>(getNotificationListQuery());

  const { mutateAsync: seenNotification } = useMutation({
    mutationKey: [`seen-notification`],
    mutationFn: (
      notification_id: Parameters<typeof NotificationAPI.seenNotification>[0],
    ) => NotificationAPI.seenNotification(notification_id),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["notifications"] }),
  });

  const handleNotification = async (notification: NotificationType) => {
    navigate(notificationTypeMapping()[notification.type].link);
    if (notification.status === "unread") {
      await seenNotification(notification.id);
    }
  };

  const unreadCount =
    initialData?.filter((item) => item.status === "unread").length || 0;

  return (
    <Menu>
      <MenuButton transition="all 0.3s" _focus={{ boxShadow: "none" }}>
        <Flex alignItems="center" gap={2}>
          <Avatar
            bg="none"
            icon={
              <IconButton
                as="div"
                size="lg"
                variant="ghost"
                aria-label="toggle menu"
              >
                <Notification size={30} />
              </IconButton>
            }
          >
            {isLoading ? (
              <AvatarBadge>
                <Spinner
                  size="sm"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="brand.500"
                />
              </AvatarBadge>
            ) : (
              unreadCount && (
                <AvatarBadge px={1} bg="brand.500">
                  <Text fontSize="xs">{unreadCount}</Text>
                </AvatarBadge>
              )
            )}
          </Avatar>
        </Flex>
      </MenuButton>
      <MenuList
        bg={useColorModeValue("white", "gray.900")}
        color={useColorModeValue("black", "white")}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        p={0}
      >
        {initialData
          ?.slice(0, 5)
          .map((notification) => (
            <SingleMenuNotification
              key={notification.id}
              notification={notification}
              onClick={handleNotification}
            />
          ))}
        {initialData?.length ? (
          <MoreNotifications length={initialData.length} />
        ) : (
          <EmptyState emptyText="No notifications" />
        )}
      </MenuList>
    </Menu>
  );
}
