import { UUID } from "crypto";

import { LoaderFunctionArgs } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { PublicAPI, SharedAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

export const getSingleMentorQuery = (mentor_id: UUID) => ({
  queryKey: ["mentor", mentor_id],
  queryFn: () => PublicAPI.getSingleMentor(mentor_id),
});

export const getSingleMenteeQuery = (mentee_id: UUID) => ({
  queryKey: ["mentee", mentee_id],
  queryFn: () => PublicAPI.getSingleMentee(mentee_id),
});

export const getMentorshipsQuery = () => ({
  queryKey: ["mentorships"],
  queryFn: SharedAPI.getMentorships,
});

export const mentorshipsLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const query = getMentorshipsQuery();

      return (
        queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query))
      );
    } catch (error) {
      captureNetworkError(error);
    }
  };
