import { createBrowserRouter, redirect } from "react-router-dom";

import {
  AUTH_ROUTES,
  CALENDLY_PREFIX,
  DASHBOARD_ROUTES,
  PUBLIC_ROUTES,
  STRIPE_PREFIX,
} from "./constants/routes";
import { loginLoader, LoginPage, RegisterPage } from "./pages/auth";
import { calendlyLoader } from "./pages/calendly";
import {
  accountLoader,
  AccountPage,
  ApplicationPage,
  applicationsLoader,
  backgroundLoader,
  BackgroundPage,
  goalsLoader,
  GoalsPage,
  GuidelinesPage,
  MentorshipPage,
  mentorshipsLoader,
  NotificationPage,
  notificationsLoader,
  servicesLoader,
  ServicesPage,
} from "./pages/dashboard";
import { protectedLoader } from "./pages/protected/loading";
import { HomePage } from "./pages/public";
import { stripeLoader } from "./pages/stripe";
import { useAuthStore } from "./services/store";
import { queryClient } from "./index";
import { DashboardLayout, MainLayout } from "./layouts";

export const router = createBrowserRouter([
  {
    id: "root",
    path: PUBLIC_ROUTES.HOME,
    Component: MainLayout,
    children: [
      {
        index: true,
        path: "/",
        Component: HomePage,
      },
      {
        path: AUTH_ROUTES.LOGIN,
        loader: loginLoader,
        Component: LoginPage,
      },
      {
        path: AUTH_ROUTES.REGISTER,
        loader: loginLoader,
        Component: RegisterPage,
      },
    ],
  },
  {
    path: DASHBOARD_ROUTES.HOME,
    Component: DashboardLayout,
    loader: protectedLoader,
    children: [
      {
        index: true,
        loader: () => redirect(DASHBOARD_ROUTES.ACCOUNT),
      },
      {
        path: DASHBOARD_ROUTES.NOTIFICATIONS,
        Component: NotificationPage,
        loader: notificationsLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.ACCOUNT,
        Component: AccountPage,
        loader: accountLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.GUIDELINES,
        Component: GuidelinesPage,
      },
      {
        path: DASHBOARD_ROUTES.GOALS,
        Component: GoalsPage,
        loader: goalsLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.BACKGROUND,
        Component: BackgroundPage,
        loader: backgroundLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.SERVICES,
        Component: ServicesPage,
        loader: servicesLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.APPLICATIONS,
        Component: ApplicationPage,
        loader: applicationsLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.MENTORSHIPS,
        Component: MentorshipPage,
        loader: mentorshipsLoader(queryClient),
      },
    ],
  },
  {
    path: `${STRIPE_PREFIX}/:return_url`,
    loader: stripeLoader,
  },
  {
    path: `${CALENDLY_PREFIX}/:return_url`,
    loader: calendlyLoader(queryClient),
  },
  {
    path: AUTH_ROUTES.LOGOUT,
    async loader() {
      // We signOut in a "resource route" that we can hit from a fetcher.Form
      await useAuthStore.getState().clearTokens();
      return redirect(PUBLIC_ROUTES.HOME);
    },
  },
]);
