import { Box, BoxProps } from "@chakra-ui/react";
import BoringAvatar from "boring-avatars";

interface CustomAvatarProps extends BoxProps {
  name?: string;
  onClick?: () => void;
  showOnline?: boolean;
}

const colorPalette = ["#26363f", "#94c888", "#26363f", "#94c045"];

export function CustomAvatar({
  name,
  showOnline,
  ...props
}: CustomAvatarProps) {
  return (
    <Box
      boxSize="96px"
      mb={4}
      pos="relative"
      _after={
        showOnline
          ? {
              content: '""',
              w: 4,
              h: 4,
              bg: "green.300",
              border: "2px solid white",
              rounded: "full",
              pos: "absolute",
              bottom: 0,
              right: 3,
            }
          : undefined
      }
      {...props}
    >
      <BoringAvatar size="100%" name={name} colors={colorPalette} />
    </Box>
  );
}
