import type { LoaderFunctionArgs } from "react-router-dom";
import { redirect } from "react-router-dom";

import { AUTH_ROUTES } from "@/constants/routes";
import { useAuthStore } from "@/services/store";

export function protectedLoader({ request }: LoaderFunctionArgs) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/auth/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!useAuthStore.getState().isAuthenticated) {
    const params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    throw redirect(`${AUTH_ROUTES.LOGIN}?` + params.toString());
  }
  return null;
}
