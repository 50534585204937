import type {
  FormControlProps,
  FormLabelProps,
  SelectProps,
} from "@chakra-ui/react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef as chakraForwardRef,
  Select,
  Text,
} from "@chakra-ui/react";
import { ArrowDown2 } from "iconsax-react";

export interface FormSelectProps extends SelectProps {
  label?: React.ReactNode;
  helperText?: string;
  labelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
}

export const FormSelect = chakraForwardRef(function FormSelect(
  props: FormSelectProps,
  ref,
) {
  const {
    label,
    helperText,
    isInvalid,
    isDisabled,
    isRequired,
    isReadOnly,
    labelProps,
    formControlProps,
    children,
    ...rest
  } = props;

  return (
    <FormControl
      id={rest.name}
      position="relative"
      isInvalid={isInvalid}
      isReadOnly={isReadOnly}
      {...formControlProps}
    >
      {label && (
        <FormLabel fontWeight="medium" display="flex" {...labelProps}>
          <Text textStyle={labelProps?.textStyle || "body2"}>{label}</Text>
          {isRequired && (
            <Text ms="1" color="red">
              *
            </Text>
          )}
        </FormLabel>
      )}
      <Select
        ref={ref}
        isDisabled={isDisabled}
        textStyle="body2"
        icon={<ArrowDown2 />}
        iconColor="blackOlive"
        iconSize="24px"
        {...rest}
      >
        {children}
      </Select>
      {helperText &&
        (isInvalid ? (
          <FormErrorMessage
            textStyle="caption1"
            fontSize="xs"
            color="error.100"
            mt={2}
          >
            {helperText}
          </FormErrorMessage>
        ) : (
          <FormHelperText mt={2} textStyle="caption1">
            {helperText}
          </FormHelperText>
        ))}
    </FormControl>
  );
});
