import {
  type LoaderFunctionArgs,
  type Params,
  redirect,
} from "react-router-dom";

import { DASHBOARD_ROUTES, STRIPE_ROUTES } from "@/constants/routes";

export function stripeLoader(args: LoaderFunctionArgs) {
  const params = args.params as Params<"return_url">;

  const url = new URL(args.request.url);
  const searchParams = new URLSearchParams(url.search);

  switch (params.return_url) {
    case STRIPE_ROUTES.CONNECT_REAUTH:
      searchParams.set("success", "false");
      return redirect(`${DASHBOARD_ROUTES.ACCOUNT}?${searchParams.toString()}`);

    case STRIPE_ROUTES.CONNECT_RETURN:
      return redirect(`${DASHBOARD_ROUTES.ACCOUNT}?${searchParams.toString()}`);

    case STRIPE_ROUTES.CHECKOUT_CANCEL:
      searchParams.set("success", "false");
      return redirect(
        `${DASHBOARD_ROUTES.APPLICATIONS}?${searchParams.toString()}`,
      );

    case STRIPE_ROUTES.CHECKOUT_SUCCESS:
      return redirect(
        `${DASHBOARD_ROUTES.APPLICATIONS}?${searchParams.toString()}`,
      );

    default:
      return redirect(DASHBOARD_ROUTES.HOME);
  }
}
