import { config } from "@/constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function captureNetworkError(error: unknown): any {
  if (config.DEBUG) {
    console.error("[NETWORK ERROR]: ", error);
  }

  if (config.isProduction && config.ENABLE_SENTRY) {
    import("./sentry").then(({ Sentry }) => {
      Sentry.captureException(error);
    });
  }
}
