import { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Edit, TickCircle } from "iconsax-react";

import { failedToast, successToast, useAuthStore, UserAPI } from "@/services";
import { dateFormatter } from "@/utils/date-formatter";
import { getRelativeTimeString } from "@/utils/relative-date";

import { getProfileQuery } from "./loading";

export function AccountPage() {
  const [isEditMode, setIsEditMode] = useState(false);

  const { data: initialData } = useQuery(getProfileQuery());

  const queryClient = useQueryClient();
  const { setUser } = useAuthStore();

  const [searchParams] = useSearchParams();
  const isConnectFailed = searchParams.get("success") === "false";

  useLayoutEffect(() => {
    if (isConnectFailed) {
      failedToast({
        title: "Account connection failed",
        description: "Please try again",
      });
    }
  }, [isConnectFailed]);

  const { mutateAsync: connectToStripe, isPending: connectToStripePending } =
    useMutation({
      mutationKey: [`connect-stripe`],
      mutationFn: () => UserAPI.connectToStripe(),
      onSuccess: async (res) => {
        if (res?.data?.url) {
          window.location.replace(res.data.url);
        } else if (res?.data?.success) {
          await queryClient.invalidateQueries({
            queryKey: ["profile"],
          });
        }
      },
    });

  const {
    mutateAsync: connectToCalendlyOAuth,
    isSuccess: connectToCalendlyOAuthPending,
  } = useMutation({
    mutationKey: [`connect-stripe`],
    mutationFn: () => UserAPI.connectToCalendlyOAuth(),
    onSuccess: async (res) => {
      if (res.data?.redirect_url) {
        window.location.replace(res.data.redirect_url);
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserAPI.UpdateUserPayload>({
    defaultValues: initialData,
  });

  const handleEditMode = () => {
    setIsEditMode(true);
  };

  const handleSubmitRegister = handleSubmit(async (values) => {
    try {
      const res = await UserAPI.updateUser(values);

      if (res.status === 200) {
        successToast({ title: "Your profile updated successfully!" });
        setUser(res.data);
        setIsEditMode(false);

        await queryClient.invalidateQueries({
          queryKey: ["profile"],
        });
      }
    } catch (error) {
      console.log({ error });
    }
  });

  const handleConnectToStripe = async () => {
    await connectToStripe();
  };
  const handleConnectToCalendly = async () => {
    await connectToCalendlyOAuth();
  };

  return (
    <Flex
      {...(isEditMode
        ? {
            as: "form",
            onSubmit: handleSubmitRegister,
          }
        : {})}
      w="100%"
      h="100%"
      p={{ base: 5, md: 20 }}
      align="flex-start"
      justify="center"
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={4}
        w="full"
        maxW="3xl"
        bg={useColorModeValue("white", "gray.900")}
        color={useColorModeValue("gray.700", "gray.200")}
        rounded="xl"
        boxShadow="lg"
        p={6}
      >
        <Flex
          pb={4}
          borderBottom={1}
          borderStyle="solid"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          justifyContent="space-between"
        >
          <Heading fontSize={{ base: "2xl", sm: "3xl" }}>
            Account Details
          </Heading>
          {!isEditMode && (
            <Flex>
              <Button
                variant="ghost"
                colorScheme="gray"
                size="sm"
                onClick={handleEditMode}
              >
                <Edit size={20} />
              </Button>
            </Flex>
          )}
        </Flex>
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            type="email"
            defaultValue={initialData?.email}
            pointerEvents="none"
            isDisabled
          />
        </FormControl>
        <FormControl
          isRequired
          id="first_name"
          isInvalid={Boolean(errors.first_name)}
        >
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            isDisabled={!isEditMode || isSubmitting}
            {...(isEditMode
              ? {
                  placeholder: "John",
                  ...register("first_name", {
                    required:
                      "You should fill the first name to complete registration",
                  }),
                }
              : {
                  value: initialData?.first_name || "",
                })}
          />
          {errors.first_name && (
            <FormErrorMessage>{errors.first_name.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isRequired
          id="last_name"
          isInvalid={Boolean(errors.last_name)}
        >
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            isDisabled={!isEditMode || isSubmitting}
            {...(isEditMode
              ? {
                  placeholder: "Doe",
                  ...register("last_name", {
                    required:
                      "You should fill the last name to complete registration",
                  }),
                }
              : {
                  value: initialData?.last_name || "",
                })}
          />
          {errors.last_name && (
            <FormErrorMessage>{errors.last_name.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl id="country" isInvalid={Boolean(errors.country)}>
          <FormLabel>Country</FormLabel>
          <Input
            type="text"
            isDisabled={!isEditMode || isSubmitting}
            {...(isEditMode
              ? {
                  placeholder: "Germany",
                  ...register("country"),
                }
              : {
                  value: initialData?.country || "",
                })}
          />
        </FormControl>
        <FormControl id="city" isInvalid={Boolean(errors.city)}>
          <FormLabel>City</FormLabel>
          <Input
            type="text"
            isDisabled={!isEditMode || isSubmitting}
            {...(isEditMode
              ? {
                  placeholder: "Berlin",
                  ...register("city"),
                }
              : {
                  value: initialData?.city || "",
                })}
          />
        </FormControl>
        <FormControl
          id="external_link"
          isInvalid={Boolean(errors.external_link)}
        >
          <FormLabel>External Link</FormLabel>
          <Input
            type="url"
            isDisabled={!isEditMode || isSubmitting}
            {...(isEditMode
              ? {
                  placeholder: "https://example.com",
                  ...register("external_link"),
                }
              : {
                  value: initialData?.external_link || "",
                })}
          />
        </FormControl>
        {initialData?.role === "mentor" && (
          <>
            <FormControl
              id="booking_link"
              isInvalid={Boolean(errors.booking_link)}
            >
              <FormLabel>Booking Link</FormLabel>
              <Input
                type="url"
                isDisabled={!isEditMode || isSubmitting}
                {...(isEditMode
                  ? {
                      placeholder: "https://example.com",
                      ...register("booking_link"),
                    }
                  : {
                      value: initialData?.booking_link || "",
                    })}
              />
            </FormControl>
            <FormControl id="tagline" isInvalid={Boolean(errors.tagline)}>
              <FormLabel>Tag Line</FormLabel>
              <Input
                isDisabled={!isEditMode || isSubmitting}
                {...(isEditMode
                  ? {
                      placeholder: "Senior Software Engineer - Google",
                      ...register("tagline"),
                    }
                  : {
                      value: initialData?.tagline || "",
                    })}
              />
            </FormControl>
          </>
        )}

        {isEditMode ? (
          <Stack spacing={6} direction={["column", "row"]}>
            <Button onClick={() => setIsEditMode(false)} w="full">
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isSubmitting}
              colorScheme="brand"
              w="full"
            >
              Submit
            </Button>
          </Stack>
        ) : (
          <>
            <Flex gap={3} alignItems="center" mt={4}>
              <Text fontWeight="bold" fontSize="lg">
                User Type:
              </Text>
              <Text fontSize="lg">{initialData?.role}</Text>
            </Flex>
            {initialData?.created_at && (
              <Flex gap={3} alignItems="center">
                <Text fontWeight="bold" fontSize="lg">
                  Join Date:
                </Text>
                <Text fontSize="lg">
                  {`${dateFormatter(initialData.created_at, {
                    dateStyle: "medium",
                    timeStyle: "short",
                  })} (${getRelativeTimeString(initialData.created_at)})`}
                </Text>
              </Flex>
            )}
            {initialData?.role === "mentor" && (
              <Flex w="full" gap={4}>
                <Button
                  isDisabled={initialData?.verified_stripe}
                  isLoading={connectToStripePending}
                  onClick={handleConnectToStripe}
                  variant={initialData.verified_stripe ? "outline" : "primary"}
                  leftIcon={
                    initialData?.verified_stripe ? (
                      <Icon as={TickCircle} size="sm" />
                    ) : undefined
                  }
                  colorScheme="brand"
                  size="sm"
                  flex={1}
                >
                  {initialData?.verified_stripe
                    ? "Connected to Stripe"
                    : "Connect to Stripe"}
                </Button>
                <Button
                  isDisabled={initialData?.verified_calendly}
                  isLoading={connectToCalendlyOAuthPending}
                  onClick={handleConnectToCalendly}
                  variant={
                    initialData.verified_calendly ? "outline" : "primary"
                  }
                  leftIcon={
                    initialData?.verified_calendly ? (
                      <Icon as={TickCircle} size="sm" />
                    ) : undefined
                  }
                  colorScheme="brand"
                  size="sm"
                  flex={1}
                >
                  {initialData?.verified_calendly
                    ? "Connected to Calendly"
                    : "Connect to Calendly"}
                </Button>
              </Flex>
            )}
          </>
        )}
      </Stack>
    </Flex>
  );
}
