import { BoxProps, FlexProps } from "@chakra-ui/react";

import { DASHBOARD_ROUTES } from "@/constants/routes";

export interface LinkItem {
  label: string;
  icon: string;
  href?: string;
}

export interface NavItemProps extends FlexProps {
  icon: string;
  children: React.ReactNode;
  href?: string;
}

export interface MobileProps extends FlexProps {
  onOpen: () => void;
}

export interface SidebarProps extends BoxProps {
  onClose: () => void;
}

export const MenteeLinkItems: Array<LinkItem> = [
  { label: "My Mentors", icon: "Teacher", href: DASHBOARD_ROUTES.MENTORSHIPS },
  {
    label: "My Applications",
    icon: "DocumentText",
    href: DASHBOARD_ROUTES.APPLICATIONS,
  },
  {
    label: "My Background",
    icon: "Profile",
    href: DASHBOARD_ROUTES.BACKGROUND,
  },
  { label: "My Goals", icon: "Activity", href: DASHBOARD_ROUTES.GOALS },
  {
    label: "Guidelines",
    icon: "Warning2",
    href: DASHBOARD_ROUTES.GUIDELINES,
  },
  { label: "My Account", icon: "Setting2", href: DASHBOARD_ROUTES.ACCOUNT },
];

export const MentorLinkItems: Array<LinkItem> = [
  { label: "My Mentees", icon: "Teacher", href: DASHBOARD_ROUTES.MENTORSHIPS },
  {
    label: "My Applications",
    icon: "DocumentText",
    href: DASHBOARD_ROUTES.APPLICATIONS,
  },
  { label: "My Services", icon: "Diamonds", href: DASHBOARD_ROUTES.SERVICES },
  {
    label: "Guidelines",
    icon: "Warning2",
    href: DASHBOARD_ROUTES.GUIDELINES,
  },
  { label: "My Account", icon: "Setting2", href: DASHBOARD_ROUTES.ACCOUNT },
];
