import { Stack, Text } from "@chakra-ui/react";

import { MentorshipProgressTypes } from "@/types";

const stepsMap: Record<
  MentorshipProgressTypes,
  {
    index: number;
    label: string;
    description: string;
  }
> = {
  phase1: { index: 1, label: "Phase 1", description: "Mutual Understanding" },
  phase2: { index: 2, label: "Phase 2", description: "Working Together" },
  phase3: { index: 3, label: "Phase 3", description: "Evaluating Results" },
};

// const stepsArr = Object.values(stepsMap);

interface MentorshipProgressProps {
  progress: MentorshipProgressTypes;
}

export function MentorshipProgress({ progress }: MentorshipProgressProps) {
  const activeStep = stepsMap[progress];

  return (
    <Stack mt={4} gap={0}>
      {/* <Stepper colorScheme="brand" size="xs" index={activeStep.index} gap="0">
        {stepsArr.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} />
            </StepIndicator>
            <StepSeparator />
          </Step>
        ))}
      </Stepper> */}
      <Text>{activeStep.label}:</Text>
      <b>{activeStep.description}</b>
    </Stack>
  );
}
