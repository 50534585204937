// browser environment variables
export const DOMAIN = import.meta.env.VITE_DOMAIN;
export const SITE_URL = import.meta.env.VITE_SITE_URL;
export const BACKEND_API_URL = import.meta.env.VITE_BACKEND_API_URL;
export const API_TIMEOUT = Number(import.meta.env.VITE_API_TIMEOUT);
export const API_STALE_TIME = Number(import.meta.env.VITE_API_STALE_TIME);

export const AUTH_COOKIE_KEY = import.meta.env.VITE_AUTH_COOKIE_KEY;
export const DEFAULT_LOCALE = import.meta.env.VITE_DEFAULT_LOCALE;

// server environment variables
export const DEBUG = import.meta.env.VITE_DEBUG === "true";
export const DEFAULT_PORT = Number(import.meta.env.VITE_DEFAULT_PORT);

// utility constants
export const isBrowser = typeof window !== "undefined";
export const isPreview = import.meta.env.VITE_VERCEL_ENV === "preview";
export const isProduction = import.meta.env.VITE_VERCEL_ENV === "production";

/**
 * third-party tools
 */
// sentry
export const ENABLE_SENTRY = import.meta.env.VITE_ENABLE_SENTRY === "true";
export const SENTRY_DEBUG = import.meta.env.VITE_SENTRY_DEBUG;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_DOMAIN_REGEX = import.meta.env.VITE_SENTRY_DOMAIN_REGEX;
export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env
  .VITE_SENTRY_TRACES_SAMPLE_RATE;
export const SENTRY_REPLAYS_SESSION_SAMPLE_RATE = import.meta.env
  .VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE;
export const SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE = import.meta.env
  .VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE;

// vercel analytics
export const ENABLE_VERCEL_ANALYTICS =
  import.meta.env.VITE_ENABLE_VERCEL_ANALYTICS === "true";
export const ENABLE_STATCOUNTER_ANALYTICS =
  import.meta.env.VITE_ENABLE_STATCOUNTER_ANALYTICS === "true";

export const STATCOUNTER_PROJECT = Number(
  import.meta.env.VITE_STATCOUNTER_PROJECT,
);
export const STATCOUNTER_INVISIBLE = Number(
  import.meta.env.VITE_STATCOUNTER_INVISIBLE,
);
export const STATCOUNTER_SECURITY = import.meta.env.VITE_STATCOUNTER_SECURITY;
