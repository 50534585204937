import { Link } from "react-router-dom";
import {
  Box,
  CloseButton,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

import { PUBLIC_ROUTES } from "@/constants/routes";
import { useAuthStore } from "@/services";

import { MenteeLinkItems, MentorLinkItems, SidebarProps } from "./config";
import { NavItem } from "./nav-item";

export function SidebarContent({ onClose, ...rest }: SidebarProps) {
  const role = useAuthStore((state) => state.user?.role);

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("brand.500", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Flex as={Link} to={PUBLIC_ROUTES.HOME} alignItems="center" gap={2}>
          <Image
            src="/assets/ofallon-labs-logo.png"
            alt="O'FALLON LABS LLC"
            boxSize={10}
          />
          <Text
            color={useColorModeValue("white", "white")}
            textAlign={useBreakpointValue({ base: "center", lg: "left" })}
            fontFamily="heading"
            fontSize="xl"
            fontWeight="semibold"
          >
            O&apos;Mentors
          </Text>
        </Flex>

        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {(role === "mentee" ? MenteeLinkItems : MentorLinkItems).map((item) => (
        <NavItem
          key={item.label}
          href={item.href}
          icon={item.icon}
          onClick={onClose}
        >
          {item.label}
        </NavItem>
      ))}
    </Box>
  );
}
