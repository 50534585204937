import { RoleTypes } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * login request
 */

export type LoginResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
};

export const login = async <T extends LoginResponse>(
  data: {
    email: string;
    password: string;
  },
  options?: AxiosRequestConfig,
) => {
  return axios.post<T, SuccessResponseType<T>>("login", data, options);
};

/**
 * verify request
 */

export type RegisterPayload = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: RoleTypes;
};

export type RegisterResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
};

export const register = async <T extends RegisterResponse>(
  data: RegisterPayload,
  options?: AxiosRequestConfig,
) => {
  return axios.post<T, SuccessResponseType<T>>("register", data, options);
};
