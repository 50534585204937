import React, { useEffect, useState } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

import "./styles.scss";

interface ConditionalLoadingProps extends BoxProps {
  isMounted: boolean;
  fallback: React.ReactNode;
  children: React.ReactNode;
}

export function ConditionalLoading({
  isMounted,
  fallback,
  children,
  ...props
}: ConditionalLoadingProps) {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChildren(isMounted);
    }, 500);

    return () => clearTimeout(timer); // Clear the timeout on component unmount
  }, [isMounted]); // Re-run the effect when isMounted prop changes

  const mountedStyle = { animation: "inAnimation 500ms ease-in" };

  return (
    <Box style={mountedStyle} {...props}>
      {showChildren ? children : fallback}
    </Box>
  );
}
