import { ElementRef, useRef, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Add } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { MentorAPI, useAuthStore } from "@/services";
import { MentorType, ServiceType } from "@/types";

import { DeleteServiceModal } from "./delete-service-modal";
import { getServicesQuery } from "./loading";
import { ModifyServiceModal } from "./modify-service-modal";
import { SingleService } from "./single-service";

export function ServicesPage() {
  const { verified_stripe } = useAuthStore((state) => state.user as MentorType);
  const { data: initialData, isFetching } =
    useQuery<MentorAPI.GetServicesResponse>(getServicesQuery());

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef<ElementRef<"input">>(null);

  const [mode, setMode] = useState<"create" | "edit" | "delete">();
  const [selected, setSelected] = useState<ServiceType | undefined>();

  const handleEditService = (selectedService: ServiceType) => {
    setSelected(selectedService);
    setMode("edit");
    onOpen();
  };

  const handleDeleteService = (selectedService: ServiceType) => {
    setSelected(selectedService);
    setMode("delete");
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    setMode("create");
    onClose();
  };

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        p={{ base: 5, md: 20 }}
        align="flex-start"
        justify="center"
      >
        <Stack
          spacing={4}
          w="full"
          maxW="3xl"
          bg={useColorModeValue("white", "gray.900")}
          color={useColorModeValue("gray.700", "gray.200")}
          rounded="xl"
          boxShadow="lg"
          p={6}
        >
          <Flex
            pb={4}
            borderBottom={1}
            borderStyle="solid"
            borderColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent="space-between"
          >
            <Heading fontSize={{ base: "2xl", sm: "3xl" }}>My Services</Heading>
            {Boolean(initialData?.length) && (
              <Button
                variant="ghost"
                colorScheme="gray"
                size="sm"
                onClick={onOpen}
              >
                <Add size={20} />
              </Button>
            )}
          </Flex>
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : initialData?.length ? (
              initialData.map((service, index) => (
                <SingleService
                  key={service.id}
                  index={index + 1}
                  service={service}
                  onEdit={handleEditService}
                  onDelete={handleDeleteService}
                />
              ))
            ) : (
              <EmptyState
                name="service"
                onClick={verified_stripe ? onOpen : undefined}
                description={
                  verified_stripe
                    ? undefined
                    : "You should connect your account first"
                }
              />
            )}
          </Flex>
        </Stack>
      </Flex>
      {mode === "delete" && selected ? (
        <DeleteServiceModal
          key={+isOpen}
          initialRef={initialRef}
          selectedService={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      ) : (
        <ModifyServiceModal
          key={+isOpen}
          initialRef={initialRef}
          selectedService={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}
