import {
  CalendlyEventType,
  MenteeType,
  MentorType,
  Prettify,
  UserType,
} from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getMe request
 */

export type GetMeResponse = Prettify<MentorType & MenteeType>;

export const getMe = async <T extends GetMeResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("user/me", options)
    .then((res) => res.data);
};

/**
 * updateUser request
 */

export type UpdateUserPayload = Partial<
  Omit<GetMeResponse, "id" | "email" | "role" | "created_at" | "updated_at">
>;

export type UpdateUserResponse = UserType;

export const updateUser = async <T extends UpdateUserResponse>(
  data: UpdateUserPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .put<T, SuccessResponseType<T>>("user/profile", data, options)
    .then((res) => res);
};

/**
 * connectToStripe request
 */

export type ConnectToStripeResponse = {
  success?: boolean;
  message?: string;
  url?: string;
};

export const connectToStripe = async <T extends ConnectToStripeResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("user/connect-stripe", options)
    .then((res) => res);
};

/**
 * connectToCalendlyOAuth request
 */

export type ConnectToCalendlyOAuthResponse = {
  redirect_url?: string;
};

export const connectToCalendlyOAuth = async <
  T extends ConnectToCalendlyOAuthResponse,
>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("user/connect-calendly/oauth", options)
    .then((res) => res);
};

/**
 * connectToCalendlyOAuthCallback request
 */

export type ConnectToCalendlyOAuthCallbackResponse = {
  success?: boolean;
  message?: string;
};

export const connectToCalendlyOAuthCallback = async <
  T extends ConnectToCalendlyOAuthCallbackResponse,
>(
  data: { code: string },
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(
      "user/connect-calendly/oauth/callback",
      data,
      options,
    )
    .then((res) => res);
};

/**
 * getMyCalendlyEvents request
 */

export type GetMyCalendlyEventsResponse = {
  collection: Array<CalendlyEventType>;
  pagination: {
    count: number;
    next_page: string | null;
    next_page_token: string | null;
    previous_page: string | null;
    previous_page_token: string | null;
  };
};

export const getMyCalendlyEvents = async <
  T extends GetMyCalendlyEventsResponse,
>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("user/calendly/events", options)
    .then((res) => res.data);
};
