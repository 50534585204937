import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { AUTH_ROUTES, DASHBOARD_ROUTES } from "@/constants/routes";
import { AuthAPI, successToast, useAuthStore, UserAPI } from "@/services";

type FormValues = {
  email: string;
  password: string;
};

export function LoginPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setAccessToken, setUser } = useAuthStore();

  const { refetch: getUserInfo } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      const userInfo = await UserAPI.getMe();
      setUser(userInfo);

      const from = searchParams.get("from");
      if (from) {
        navigate(from);
      } else if (userInfo.role === "mentee") {
        navigate(DASHBOARD_ROUTES.GUIDELINES);
      } else {
        navigate(DASHBOARD_ROUTES.GUIDELINES);
      }

      return userInfo;
    },
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const handleSubmitLogin = handleSubmit(async ({ email, password }) => {
    try {
      const res = await AuthAPI.login({ email, password });

      if (res.status === 200) {
        successToast({ title: "Logging in successfully!" });
        setAccessToken(res.data.access_token);

        await getUserInfo();
      }
    } catch (error) {
      console.log({ error });
    }
  });

  return (
    <Flex
      as="form"
      onSubmit={handleSubmitLogin}
      w="100%"
      h="100%"
      p={{ base: 0, sm: 12 }}
      bg={{ base: "white", lg: "brand.background" }}
      align="center"
      justify="center"
    >
      <Box
        textAlign="center"
        flex={1}
        maxW="735px"
        rounded="lg"
        bg="white"
        px={{ base: 6, lg: 24 }}
        py={{ base: 6, lg: 12 }}
      >
        <Box maxW="544px">
          <Text mb={{ base: 7, lg: 16 }} textStyle="h1">
            Welcome back to O’Mentors
          </Text>
          <Flex gap={3} flexDirection="column">
            <FormControl id="email" isInvalid={Boolean(errors.email)}>
              <FormLabel>Email address *</FormLabel>
              <Input
                required
                type="email"
                {...register("email", {
                  required: "You must provide a username to log in",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl id="password" isInvalid={Boolean(errors.password)}>
              <FormLabel>Password *</FormLabel>
              <Input
                required
                type="password"
                {...register("password", {
                  required: "You must provide a password to log in",
                })}
              />
              {errors.password && (
                <FormErrorMessage>{errors.password.message}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
          <Flex gap={1} mt={{ base: 8, lg: 12 }} flexDirection="column">
            <Button
              type="submit"
              w="full"
              isLoading={isSubmitting}
              h={12}
              borderRadius="md"
              loadingText="Logging in..."
              size="lg"
              bg="button.green"
              color="white"
              _hover={{
                bg: "brand.500",
              }}
            >
              Log In
            </Button>
            <Button
              isDisabled
              variant="link"
              as={Link}
              to={AUTH_ROUTES.FORGET}
              alignSelf="self-end"
              fontWeight="normal"
              fontSize="sm"
              lineHeight={7}
            >
              Forgot password?
            </Button>
          </Flex>
          <Box
            mt={{ base: 12, lg: 8 }}
            mb={{ base: 8, lg: 12 }}
            position="relative"
          >
            <Divider />
            <AbsoluteCenter bg="white" px="4">
              OR
            </AbsoluteCenter>
          </Box>
          <Flex flexDirection="column" gap={{ base: 8, lg: 6 }}>
            <Button
              isDisabled
              variant="outline"
              alignItems="center"
              justifyContent="center"
              gap={3}
              h={12}
            >
              <Image src="/assets/google.svg" boxSize={5} />
              <Text
                lineHeight="21px"
                fontSize="md"
                fontWeight="bold"
                color="#434343"
              >
                Log in with Google
              </Text>
            </Button>
            <Divider />
          </Flex>
          <Flex mt={12} gap={6} flexDirection="column">
            <Text fontWeight="normal" fontSize="md" lineHeight={7}>
              Don’t have an account?
            </Text>
            <Button
              variant="link"
              as={Link}
              to={AUTH_ROUTES.REGISTER}
              fontWeight="bold"
              color="brand.600"
            >
              Sign up as a mentee
            </Button>
            <Button
              variant="link"
              as={Link}
              to={AUTH_ROUTES.REGISTER}
              fontWeight="bold"
              color="brand.600"
            >
              Apply to become a mentor
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}
