import type { IconType } from "react-icons";

export interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  icon?: IconType;
}

export const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Learning Center",
    href: "https://saeedmirshekari.com/blog/",
  },
  {
    label: "Contact Us",
    href: "https://saeedmirshekari.com/contact/",
  },
];
