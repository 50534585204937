import { ElementRef, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import {
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { SearchNormal } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { PUBLIC_ROUTES } from "@/constants/routes";
import { failedToast, SharedAPI, useAuthStore } from "@/services";
import { ApplicationResponseStatusTypes, ApplicationType } from "@/types";
import { filterByName } from "@/utils/filter-by-name";

import { AuthorDetails } from "./author-details";
import { CancelApplicationModal } from "./cancel-application-modal";
import { getApplicationsQuery } from "./loading";
import { RespondApplicationModal } from "./respond-application-modal";
import { SingleApplication } from "./single-application";

export function ApplicationPage() {
  const { data: initialData, isFetching } =
    useQuery<SharedAPI.GetApplicationsResponse>(getApplicationsQuery());
  const { isOpen, onOpen, onClose } = useDisclosure();

  const role = useAuthStore((state) => state.user?.role);

  const initialRef = useRef<ElementRef<"textarea">>(null);

  const [mode, setMode] = useState<
    ApplicationResponseStatusTypes | "cancel" | "details" | undefined
  >();
  const [selected, setSelected] = useState<ApplicationType | undefined>();

  const { register, watch } = useForm<{ query: string }>({
    defaultValues: { query: "" },
  });

  const [searchParams] = useSearchParams();
  const isPaymentFailed = searchParams.get("success") === "false";

  useLayoutEffect(() => {
    if (isPaymentFailed) {
      failedToast({
        title: "Application payment failed or canceled",
        description: "Please try again",
      });
    }
  }, [isPaymentFailed]);

  const isMentee = role === "mentee";
  const filteredApplications = initialData?.filter(
    (application) =>
      filterByName(
        isMentee
          ? application.mentor?.first_name || ""
          : application.mentee?.first_name || "",
        watch("query"),
      ) ||
      filterByName(
        isMentee
          ? application.mentor?.last_name || ""
          : application.mentee?.last_name || "",
        watch("query"),
      ),
  );

  const handleShowDetails = (selectedApplication: ApplicationType) => {
    setSelected(selectedApplication);
    setMode("details");
    onOpen();
  };

  const handleRespondApplication = (
    response: ApplicationResponseStatusTypes,
    selectedApplication: ApplicationType,
  ) => {
    setSelected(selectedApplication);
    setMode(response);
    onOpen();
  };

  const handleCancelApplication = (selectedApplication: ApplicationType) => {
    setSelected(selectedApplication);
    setMode("cancel");
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    setMode(undefined);
    onClose();
  };

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        p={{ base: 5, md: 20 }}
        align="flex-start"
        justify="center"
      >
        <Stack
          spacing={4}
          w="full"
          maxW="3xl"
          bg={useColorModeValue("white", "gray.900")}
          color={useColorModeValue("gray.700", "gray.200")}
          rounded="xl"
          boxShadow="lg"
          p={6}
        >
          <Flex
            pb={4}
            borderBottom={1}
            borderStyle="solid"
            borderColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent="space-between"
            gap={6}
            direction={{ base: "column", lg: "row" }}
          >
            <Heading fontSize={{ base: "2xl", sm: "3xl" }}>
              My Applications
            </Heading>
            <Stack direction="row">
              <Input
                size="sm"
                placeholder="Search..."
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300",
                }}
                {...register("query")}
              />
              <IconButton
                aria-label="Search"
                size="sm"
                colorScheme="brand"
                icon={<SearchNormal />}
              />
              {role === "mentee" && (
                <Button
                  as={Link}
                  to={PUBLIC_ROUTES.HOME}
                  colorScheme="brand"
                  size="sm"
                >
                  Apply
                </Button>
              )}
            </Stack>
          </Flex>
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : filteredApplications?.length ? (
              <Stack
                direction={{ base: "column", md: "row" }}
                textAlign="center"
                justify="flex-start"
                spacing={{ base: 4, lg: 10 }}
                py={10}
                overflowX="auto"
              >
                {filteredApplications.map((application) => (
                  <SingleApplication
                    key={application.id}
                    isMentee={isMentee}
                    application={application}
                    onDetail={handleShowDetails}
                    onCancel={handleCancelApplication}
                    onRespond={handleRespondApplication}
                  />
                ))}
              </Stack>
            ) : (
              <EmptyState name="application" />
            )}
          </Flex>
        </Stack>
      </Flex>
      {selected &&
        (mode === "details" ? (
          <AuthorDetails
            isMentee={isMentee}
            application={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === "cancel" ? (
          <CancelApplicationModal
            key={+isOpen}
            initialRef={initialRef}
            selectedApplication={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode ? (
          <RespondApplicationModal
            key={+isOpen}
            initialRef={initialRef}
            selectedApplication={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
            action={mode}
          />
        ) : (
          <></>
        ))}
    </>
  );
}
