import {
  Badge,
  Button,
  Flex,
  Heading,
  SkeletonText,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { CustomAvatar } from "@/components";
import { SharedAPI } from "@/services";
import {
  ApplicationResponseStatusTypes,
  ApplicationStatusTypes,
  ApplicationType,
} from "@/types";
import { dateFormatter } from "@/utils/date-formatter";
import { getFullName } from "@/utils/get-user-name";
import { getRelativeTimeString } from "@/utils/relative-date";

const applicationStatusColorMap: Record<ApplicationStatusTypes, string> = {
  new: "gray",
  payment: "blue",
  canceled: "orange",
  accepted: "green",
  rejected: "red",
};

interface ApplicationResponseProps {
  isMentee: boolean;
  application: ApplicationType;
  onPay: (application: ApplicationType) => void;
  onCancel: (application: ApplicationType) => void;
  onRespond: (
    response: ApplicationResponseStatusTypes,
    application: ApplicationType,
  ) => void;
  isLoading?: boolean;
}

function ApplicationResponse({
  isMentee,
  application,
  onPay,
  onCancel,
  onRespond,
  isLoading,
}: ApplicationResponseProps) {
  switch (application.status) {
    case "new":
      if (isMentee) {
        return (
          <Button
            variant="outline"
            flex={1}
            rounded="full"
            colorScheme="red"
            onClick={() => onCancel(application)}
          >
            Withdraw Application
          </Button>
        );
      }
      return (
        <>
          <Button
            flex={1}
            rounded="full"
            colorScheme="red"
            onClick={() => onRespond("rejected", application)}
          >
            Reject
          </Button>
          <Button
            flex={1}
            rounded="full"
            colorScheme="green"
            onClick={() => onRespond("accepted", application)}
          >
            Accept
          </Button>
        </>
      );

    case "payment":
      if (isMentee) {
        return (
          <Button
            onClick={() => onPay(application)}
            isLoading={isLoading}
            flex={1}
            rounded="full"
            colorScheme="brand"
          >
            Pay Now
          </Button>
        );
      }
      return (
        <Button isDisabled flex={1} rounded="full">
          Waiting for payment
        </Button>
      );

    case "canceled":
      return (
        <Button isDisabled flex={1} fontSize="sm" rounded="full">
          Application Canceled
        </Button>
      );

    default:
      return (
        <Button isDisabled flex={1} fontSize="sm" rounded="full">
          {isMentee ? "Application Sent" : "Done"}
        </Button>
      );
  }
}

interface ApplicationCardProps {
  isMentee: boolean;
  application: ApplicationType;
  onDetail: (application: ApplicationType) => void;
  onCancel: (application: ApplicationType) => void;
  onRespond: (
    response: ApplicationResponseStatusTypes,
    application: ApplicationType,
  ) => void;
}

export function SingleApplication({
  isMentee,
  application,
  onDetail,
  onCancel,
  onRespond,
}: ApplicationCardProps) {
  const memberInfo = isMentee ? application?.mentor : application?.mentee;

  const { mutateAsync: payApplication, isPending: payApplicationLoading } =
    useMutation({
      mutationKey: [`pay-application`, application.id],
      mutationFn: (
        application_id: Parameters<typeof SharedAPI.payApplication>[0],
      ) => SharedAPI.payApplication(application_id),
      onSuccess: (res) =>
        res?.data?.url && window.location.replace(res.data.url),
    });

  const handlePayApplication = async () => {
    await payApplication(application.id);
  };

  return (
    <Flex
      direction="column"
      maxW={{ base: "full", md: "275px" }}
      w="full"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      flexShrink={0}
      p={5}
      bg={useColorModeValue("white", "gray.900")}
      rounded="lg"
      justifyContent="center"
      alignItems="center"
    >
      <CustomAvatar name={getFullName(memberInfo!)} />
      <SkeletonText
        w="full"
        mb={4}
        noOfLines={2}
        lineHeight={1}
        skeletonHeight="5"
        display="flex"
        flexDirection="column-reverse"
        alignItems="center"
        gap={2}
        fadeDuration={1}
        isLoaded={Boolean(memberInfo)}
      >
        <Heading
          fontSize="2xl"
          fontFamily="body"
          mb={2}
          onClick={() => onDetail(application)}
          cursor="pointer"
        >
          {getFullName(memberInfo!)}
        </Heading>
        <Text fontWeight={600} color="gray.500">
          {memberInfo!.email}
        </Text>
      </SkeletonText>
      <Badge colorScheme={applicationStatusColorMap[application.status]}>
        {application.status.toUpperCase()}
      </Badge>
      <Flex direction="column">
        <Text
          fontSize="xs"
          fontWeight="bold"
          textAlign="center"
          color={useColorModeValue("gray.700", "gray.400")}
          mt={4}
        >
          Service Title:
        </Text>
        <Text
          as="q"
          fontSize="xs"
          fontStyle="italic"
          textAlign="center"
          color={useColorModeValue("gray.700", "gray.400")}
          mb="auto"
          noOfLines={2}
        >
          {application.service?.title}
        </Text>
      </Flex>
      <Flex alignItems="center" gap={2} mt={4} mb="auto">
        <Text
          fontSize="xs"
          fontWeight="bold"
          textAlign="center"
          color={useColorModeValue("gray.700", "gray.400")}
        >
          Service Price:
        </Text>
        <Text
          fontSize="xs"
          textAlign="center"
          color={useColorModeValue("gray.700", "gray.400")}
          noOfLines={2}
        >
          {`${
            application.service?.price
              ? `$${application.service?.price}`
              : "Free"
          } (${application.service?.type})`}
        </Text>
      </Flex>
      <Text
        fontSize="xs"
        fontWeight="bold"
        textAlign="center"
        color={useColorModeValue("gray.700", "gray.400")}
        mt={4}
      >
        {isMentee ? "Response Message:" : "Request Note:"}
      </Text>
      <Text
        as="q"
        fontSize="xs"
        fontStyle="italic"
        textAlign="center"
        color={useColorModeValue("gray.700", "gray.400")}
        mb="auto"
        noOfLines={2}
      >
        {(isMentee ? application.response_message : application.request_note) ||
          "N/A"}
      </Text>
      <Text
        mt={4}
        fontSize="xs"
        fontWeight="bold"
        textAlign="center"
        color={useColorModeValue("gray.700", "gray.400")}
      >
        Applied at:
      </Text>
      <Text fontSize="xs" textAlign="center" colorScheme="blue" noOfLines={1}>
        {`${dateFormatter(application.created_at, {
          dateStyle: "medium",
        })} (${getRelativeTimeString(application.created_at)})`}
      </Text>

      <Stack mt={8} direction="row" spacing={4} w="full" maxW="md">
        <ApplicationResponse
          isMentee={isMentee}
          application={application}
          onPay={handlePayApplication}
          isLoading={payApplicationLoading}
          onCancel={onCancel}
          onRespond={onRespond}
        />
      </Stack>
    </Flex>
  );
}
