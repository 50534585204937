import { useNavigate } from "react-router-dom";
import { Flex, Heading, Stack, useColorModeValue } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { EmptyState, LoadingState } from "@/components";
import { notificationTypeMapping } from "@/constants/mappings";
import { NotificationAPI } from "@/services";
import { NotificationType } from "@/types";

import { getNotificationListQuery } from "./loading";
import { SingleNotification } from "./single-notification";

export function NotificationPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: initialData, isFetching } =
    useQuery<NotificationAPI.GetNotificationList>(getNotificationListQuery());

  const { mutateAsync: seenNotification } = useMutation({
    mutationKey: [`seen-notification`],
    mutationFn: (
      notification_id: Parameters<typeof NotificationAPI.seenNotification>[0],
    ) => NotificationAPI.seenNotification(notification_id),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["notifications"] }),
  });

  const handleNotification = async (notification: NotificationType) => {
    navigate(notificationTypeMapping()[notification.type].link);
    if (notification.status === "unread") {
      await seenNotification(notification.id);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        p={{ base: 5, md: 20 }}
        align="flex-start"
        justify="center"
      >
        <Stack
          spacing={4}
          w="full"
          maxW="3xl"
          bg={useColorModeValue("white", "gray.900")}
          color={useColorModeValue("gray.700", "gray.200")}
          rounded="xl"
          boxShadow="lg"
          p={6}
        >
          <Flex
            pb={4}
            borderBottom={1}
            borderStyle="solid"
            borderColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent="space-between"
          >
            <Heading fontSize={{ base: "2xl", sm: "3xl" }}>
              My Notifications
            </Heading>
          </Flex>
          <Flex direction="column" gap={4}>
            {isFetching ? (
              <LoadingState />
            ) : initialData?.length ? (
              initialData.map((notification) => (
                <SingleNotification
                  key={notification.id}
                  notification={notification}
                  onClick={handleNotification}
                />
              ))
            ) : (
              <EmptyState name="notification" />
            )}
          </Flex>
        </Stack>
      </Flex>
    </>
  );
}
