import {
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from "@chakra-ui/react";

import { Education } from "@/pages/dashboard/background/education";
import { Job } from "@/pages/dashboard/background/job";
import { Skills } from "@/pages/dashboard/background/skills";
import { Tools } from "@/pages/dashboard/background/tools";
import { SingleGoal } from "@/pages/dashboard/goals/single-goal";
import { GoalType, MenteeType } from "@/types";

import { EmptyState, LoadingState } from "../index";

interface MenteeDetailsProps {
  mentee: MenteeType;
}

function MenteeDetails({ mentee }: MenteeDetailsProps) {
  return mentee ? (
    <Flex direction="column" gap={6}>
      <Heading fontSize="2xl" fontWeight="semibold" mb={2}>
        {`${mentee.first_name}'s Background:`}
      </Heading>
      <Flex direction="column" gap={6}>
        {mentee.background ? (
          <>
            <Flex direction={{ base: "column", md: "row" }} gap={6}>
              <Education background={mentee.background} />
              <Job background={mentee.background} />
            </Flex>
            <Flex direction={{ base: "column", md: "row" }} gap={6}>
              <Skills
                background={mentee.background}
                emptyText="They don't added any skills yet."
              />
              <Tools
                background={mentee.background}
                emptyText="They don't added any tools yet."
              />
            </Flex>
          </>
        ) : (
          <EmptyState
            name="background"
            emptyText="They don't added their background yet."
          />
        )}
      </Flex>
      <Divider />
      <Heading fontSize="2xl" fontWeight="semibold" mb={2}>
        {`${mentee.first_name}'s Goals:`}
      </Heading>
      {mentee.goals.length ? (
        <Stack
          w="100%"
          direction={{ base: "column", md: "row" }}
          textAlign="center"
          justify="flex-start"
          spacing={{ base: 4, lg: 10 }}
          overflowX="auto"
        >
          {mentee.goals.map((goal, index) => (
            <SingleGoal
              key={goal.id}
              index={index + 1}
              goal={goal as GoalType}
            />
          ))}
        </Stack>
      ) : (
        <EmptyState name="goals" emptyText="They don't added any goals yet." />
      )}
    </Flex>
  ) : (
    <LoadingState />
  );
}

interface MenteeModalProps extends Omit<ModalProps, "children"> {
  mentee: MenteeType;
}

export function MenteeModal({
  mentee,
  isOpen,
  onClose,
  ...props
}: MenteeModalProps) {
  return mentee ? (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          {mentee ? <MenteeDetails mentee={mentee} /> : <LoadingState />}
        </ModalBody>
        <ModalFooter gap={4}></ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  );
}
